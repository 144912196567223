import { Box, Text, useToast, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import API from 'src/util/api'
import Config from 'src/util/config'

const UserValidation = () => {
  const history = useHistory()
  const toast = useToast()
  const { user: auth } = useUser()
  const params = useParams()
  let search = useLocation().search
  search = new URLSearchParams(search)
  const onActivation = async () => {
    //toast.closeAll()

    const response = await API.post(Config.API_URI + 'validateUser', {
      email: search.get('email'),
      isNotValidated: false,
      token: search.get('token'),
    })
    if (response && response?.statusCode !== 201) {
      toast({
        status: 'success',
        title: 'You have successfully activated your account',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
      history.push('/language')
    } else {
      toast({
        status: 'warning',
        title: response.message
          ? response.message
          : 'You have not successfully activated your account, please try after sometime.',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: {
          fontSize: '14px',
        },
      })
    }
  }
  useEffect(() => {
    onActivation()
  }, [])
  return (
    <VStack p="4" py={10} alignItems={'center'} justifyContent={'flex-start'}>
      <Box>
        <Text
          fontSize="13px"
          color="#000"
          opacity={'.5'}
          pl={'2'}
          whiteSpace="nowrap"
        >
          We appreciate your time to validate your account, please enjoy
          learning with us.
        </Text>
      </Box>
      <Box>
        <Text
          fontSize="13px"
          color="#000"
          opacity={'.5'}
          pl={'2'}
          whiteSpace="nowrap"
        >
          You can connect with us at any time if you have any trouble, using our
          support desk.
        </Text>
      </Box>
    </VStack>
  )
}

export default UserValidation
