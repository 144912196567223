import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ResetPassword from 'src/component/ResetPassword'

import MainContainer from './component/CourseDetail/MainContainer'
import UserValidation from './component/UserValidation'
import Analytics from './Container/Analytics'
import Checkout from './Container/Checkout'
import CourseDetails from './Container/CourseDetails'
import FeatureFlags from './Container/FeatureFlags'
import MyCourses from './Container/MyCourses'
import Notification from './Container/Notification'
import PaymentFailed from './Container/PaymentFailed'
import PaymentSuccess from './Container/PaymentSuccess'
import Practice from './Container/Practice'
import Pricing from './Container/Pricing'
import PricingRzp from './Container/PricingRzp'
import UserProfile from './Container/UserProfile/UserProfile'
import { useFlags } from './redux/useFeatureFlags'
import NotFound from './component/NotFoundPage'

function App() {

  return (
    <Switch>
      <Route path="/" exact={true} component={MyCourses} />
      <Route path="/language" exact={true} component={MyCourses} />
      <Route path="/language/:courseId" exact={true} component={MyCourses} />
      <Route
        path="/user/validate-user"
        exact={true}
        component={UserValidation}
      />
      <Route path="/notification" exact={true} component={Notification} />
      <Route path="/userprofile" exact={true} component={UserProfile} />
      <Route path="/analytics" exact={true} component={Analytics} />
      <Route
        path="/coursedetails/:courseId"
        exact={true}
        component={CourseDetails}
      />
      <Route
        path="/introduction/course/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId"
        exact={true}
        component={MainContainer}
      />
      <Route
        path="/course/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId/practice"
        exact={true}
        component={Practice}
      />
      <Route
        path="/introduction/course/:courseId/chapter/:chapterId/unit/:unitId"
        exact={true}
        component={MainContainer}
      />

      <Route
        path="/reset-password/:encrypteddata"
        exact={true}
        component={ResetPassword}
      />
      <Route path="/payment-success" exact component={PaymentSuccess} />
      <Route path="/payment-failed" exact component={PaymentFailed} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/features" exact component={FeatureFlags} />
      <Route
        path="/pricing"
        exact
        component={Pricing}
      />
      <Route path="/pricing-new" exact component={PricingRzp} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
export default App
