import './homepage.css'

import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Slider from 'react-slick-slider'
import HomePageLinguals from 'src/constants/homepage-linguals'

import { isMobile } from '../CourseDetail/Practice/Util'
import Languages from './Languages'

const blockName = 'languages-offered'
// 768-960
const LanguagesOffered = () => {
  const history = useHistory()
  const courses = useSelector(store => store.mainReducer.courses)
  const isMobileScreen = useMediaQuery('(min-width: 0px)')[0]
  const isTabletScreen = useMediaQuery('(min-width: 768px)')[0]
  const langSelectionHandlr = lang => {
    localStorage.setItem('langSelectedFromHomePage', JSON.stringify(lang))
    localStorage.setItem('selectedLanguageId', lang.id)
    localStorage.setItem('selectedLanguage', JSON.stringify(lang))
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }

  const renderLanguages = () => {
    return courses?.map(lang => (
      <Box>
        <Languages langSelectionHandlr={langSelectionHandlr} item={lang} />
      </Box>
    ))
  }

  const slidesToShow = () => {
    if (isMobile && !isTabletScreen[0]) {
      return 2
    } else if (isMobile && isTabletScreen[0]) {
      return 4
    } else return 5
  }

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow(),
    slidesToScroll: 1,
  }
  const shouldRenderSlider = () => {
    if (isMobile) {
      if (isMobileScreen && !isTabletScreen) {
        if (courses.length > 2) {
          return true
        }
      } else if (!isMobileScreen && isTabletScreen) {
        if (courses.length > 5) {
          return true
        }
      } else return false
    } else if (!isMobile) {
      if (courses.length > 5) {
        return true
      }
    } else return false
  }

  return (
    <Box className={blockName}>
      <Text
        textAlign="center"
        fontWeight={700}
        lineHeight="42px"
        marginBottom="40px"
        fontSize={['20px', '20px', '32px']}
      >
        {HomePageLinguals.languageOfferTitle}
      </Text>{' '}
      {shouldRenderSlider() ? (
        <Box className="container">
          <Slider infinite {...settings}>
            {renderLanguages()}
          </Slider>
        </Box>
      ) : (
        <HStack gap="30px" justify={'center'} py={'30px'} mt={'34px'}>
          {renderLanguages()}
        </HStack>
      )}
    </Box>
  )
}

export default LanguagesOffered
