import { Box, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import React from 'react'
import happyFaceData from 'src/asset/images/happyFace.json'
import sadFace from 'src/asset/images/sadFace.json'
import { useUser } from 'src/hooks/useUser'
import { ExcerciseTypes } from 'src/util/helpers'

import usePracticeNav, { ACTIVITY_TYPE } from './usePracticeNav'

function PracticeNav({
  selectedAnswer,
  exerciseTodo,
  setSelectedAnswer,
  exerciseCount,
  setExerciseCount,
  setDisableOptions,
  vocabularyCount,
  vocabularyToDo,
  setVocabularyCount,
  handleVocabulary,
  activityScreen,
  setActivityScreen,
  handleSubmitFeedback,
}) {
  const {
    handleMoveOnNextPage,
    handleCheck,
    isCorrectAnswer,
    isSelectedAnswerCorrect,
    onEditExerciseClick,
    onEditMaterExerciseClick,
  } = usePracticeNav({
    selectedAnswer,
    exerciseTodo,
    setSelectedAnswer,
    exerciseCount,
    setExerciseCount,
    setDisableOptions,
    vocabularyCount,
    vocabularyToDo,
    setVocabularyCount,
    handleVocabulary,
    setActivityScreen,
  })

  const { isAdmin } = useUser()
  const currentExercise = exerciseTodo[exerciseCount]
  const isWatchVideoOnly =
    currentExercise?.questionType === ExcerciseTypes.WATCH_VIDEO
  const isListenWithType =
    currentExercise?.questionType === ExcerciseTypes.LISTEN_WITH_TYPE
  const correctAnswer = isWatchVideoOnly
    ? isCorrectAnswer()
    : isSelectedAnswerCorrect

  // if (isPronounceExercise) {
  //   return (
  //     <VocabularyNavBar
  //       handleRemindSkip={handleReminedSkip}
  //       handleIKnow={handleIKnow}
  //     />
  //   )
  // }

  if (activityScreen === ACTIVITY_TYPE.Feedback) {
    return (
      <FeedbackNavBar
        disabled={selectedAnswer === null}
        onSubmitFeedback={handleSubmitFeedback}
      />
    )
  }

  if (correctAnswer !== null && selectedAnswer) {
    return (
      <ExerciseResult
        onNextClick={() => handleMoveOnNextPage(false)}
        correctAnswer={correctAnswer}
      />
    )
  }

  return (
    <BottomNavContainer>
      <Button
        variant="secondary"
        onClick={() => handleMoveOnNextPage(true)}
        size="lg"
      >
        Skip
      </Button>
      <HStack justifyContent="flex-end" width="full">
        {isAdmin && (
          <>
            <Button variant="secondary" onClick={onEditExerciseClick} size="lg">
              Edit Ex
            </Button>
            <Button
              variant="secondary"
              onClick={onEditMaterExerciseClick}
              size="lg"
            >
              Edit ExM
            </Button>
          </>
        )}
        <Button
          variant="primary"
          hidden={isWatchVideoOnly}
          disabled={
            selectedAnswer === null || (isListenWithType && !selectedAnswer)
          }
          onClick={handleCheck}
          ml="auto"
          size="lg"
        >
          Check
        </Button>
        <Button
          variant="primary"
          hidden={!correctAnswer}
          marginInlineStart={'auto'}
          onClick={() => {
            handleMoveOnNextPage(false)
          }}
          size="lg"
        >
          Next
        </Button>
      </HStack>
    </BottomNavContainer>
  )
}

function ExerciseResult({ correctAnswer, onNextClick }) {
  const border = 'none'
  const bgColor = correctAnswer
    ? 'transparent linear-gradient(357deg, #C0FFC8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box'
    : 'transparent linear-gradient(0deg, #FF7979 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;'
  const iconSrc = correctAnswer ? happyFaceData : sadFace

  return (
    <BottomNavContainer background={bgColor} border={border}>
      <HStack flex="1">
        <Lottie
          animationData={iconSrc}
          autoplay={true}
          style={{ width: 60, height: 60 }}
          // lottieRef={fastAudioRef}
        />
        {/* <Image src={iconSrc} /> */}
        <Box>
          <Heading size={'sm'}>
            {correctAnswer ? `That's Correct` : `Oh no! that's incorrect`}
          </Heading>
          <Text fontSize="xs">
            {correctAnswer ? 'Tremendous!' : `Please try again`}
          </Text>
        </Box>
      </HStack>
      <HStack justifyContent="flex-end">
        <Button
          variant="primary"
          marginInlineStart={'auto'}
          hidden={!correctAnswer}
          onClick={onNextClick}
        >
          Next
        </Button>
      </HStack>
    </BottomNavContainer>
  )
}

function FeedbackNavBar({ disabled, onSubmitFeedback }) {
  return (
    <BottomNavContainer>
      <Button ml="auto" size="lg" variant="primary" onClick={onSubmitFeedback}>
        Submit
      </Button>
    </BottomNavContainer>
  )
}

const BottomNavContainer = ({ children, background, border, ...props }) => {
  return (
    <Stack
      direction={'row'}
      borderTop={border || '0.2px solid #CBD5E0'}
      position="fixed"
      bottom="0"
      h="80px"
      width="100%"
      padding="20px"
      alignItems={'center'}
      background={background || 'white'}
      {...props}
    >
      <HStack width={['auto', 'auto', '70%']} mx="auto">
        {children}
      </HStack>
    </Stack>
  )
}

export default PracticeNav
