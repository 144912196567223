import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { Box, Image } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'

import { getFileUrl, isMobile } from '../CourseDetail/Practice/Util'

const ImageSlider = ({
  slides,
  px,
  sx,
  borderRadius,
  centerMode = false,
  onChange,
  autoPlay = false,
  onClick,
  interval = 3000,
  width = '100%',
}) => {
  const getImageUrl = item => {
    return item?.image
      ? item.image
      : getFileUrl(isMobile ? item?.mobileImageUrl : item?.imageUrl)
  }
  return (
    <Box cursor={'pointer'}>
      <Carousel
        infiniteLoop
        px={px}
        autoFocus={false}
        centerMode={centerMode}
        autoPlay={autoPlay}
        interval={interval}
        emulateTouch={true}
        dynamicHeight={true}
        onChange={onChange}
        onClickItem={onClick}
        showStatus={false}
        showThumbs={false} //To Resolve main page warnings
      >
        {slides?.map(item => {
          return (
            <Image
              sx={sx}
              key={getImageUrl(item)}
              borderRadius={borderRadius}
              src={getImageUrl(item)}
              style={{
                width: width,
              }}
            />
          )
        })}
      </Carousel>
    </Box>
  )
}
export default ImageSlider
