import { Button, Heading, VStack } from '@chakra-ui/react'
const Experience = ({redirectToSignUp}) => {
  return (
    <VStack className="joyful-experience-container" color={'#fff'}>
      <Heading
        textAlign={'center'}
        fontSize={['20px', '20px', '24px']}
        lineHeight={['30px', '30px', '38px']}
      >
        Ready for a Joyful <br /> Learning Language Experience?
      </Heading>
      <Button
        color="#000000"
        backgroundColor={'#fff'}
        w={['160px', '160px', '220px']}
        h={['42px', '42px', '48px']}
        onClick={redirectToSignUp}
      >
        Get Started
      </Button>
    </VStack>
  )
}

export default Experience
