import create from 'zustand'
import vanillaCreate from 'zustand/vanilla'

export const tokenStore = vanillaCreate((set) => ({
  token: localStorage.getItem('token') || undefined,
  setToken: (newToken) => {
    set({ token: newToken });
    localStorage.setItem('token', newToken || '');
  },
}))

export const useTokenStore = create(tokenStore)
