import { Box } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Toolbar from './component/Toolbar'

function Layout({ children }) {
  const location = useLocation()
  const path = location.pathname;
  console.log("path", path)
  const hideToolbarPaths = ['/introduction/course']
  const shouldNotShowHeader = () => {
    return hideToolbarPaths.some(elem => path.includes(elem))
  }

  return (
    <Box>
      {!shouldNotShowHeader() && <Toolbar />}
      {children}
    </Box>
  )
}

export default Layout
