import { Box, Image, List, ListItem, Text } from '@chakra-ui/react'
import formalLanguageIcon from 'src/asset/images/formal-language.svg'
import highlyFrequentIcon from 'src/asset/images/highly-frequent.svg'
import langWithHumanIcon from 'src/asset/images/lang-with-human.svg'
import whatwebelieveVideo from 'src/asset/videos/What we believe.mp4'

const WhatWeBelieve = () => {
  return (
    <Box
      p={'0px 36px'}
      mt="36px !important"
      mb="40px !important"
      className="our-methods-container"
    >
      <Text
        textAlign="center"
        fontWeight={800}
        mb={['24px', '24px', '40px']}
        fontSize={['20px', '20px', '32px']}
        lineHeight="36px"
      >
        What we believe
      </Text>
      <Box
        py="20px"
        display={'flex'}
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={['', '', '50%', '']}
          mr={['0px', '0px', '30px', '0px']}
          className="our-methods-image"
        >
          <video
            autoplay="autoplay"
            loop="loop"
            muted
            defaultMuted
            playsinline
            oncontextmenu="return false;"
            preload="auto"
          >
            <source src={whatwebelieveVideo} type="video/mp4" />
          </video>
        </Box>
        <Box
          mt={['25px', '25px', 'none']}
          width={['100%', '100%', '50%', '30%']}
          className="our-methods"
        >
          <List spacing={'35px'}>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="we-beleive-icon" src={highlyFrequentIcon} />
                Learn highly frequent words, grammar & language skills first.
              </Text>
            </ListItem>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="we-beleive-icon" src={langWithHumanIcon} />
                Learn the language with human not with robots.
              </Text>
            </ListItem>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="we-beleive-icon" src={formalLanguageIcon} />
                Formal language is less relevant now We teach colloquial
                speaking with native people & teachers.
              </Text>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default WhatWeBelieve
