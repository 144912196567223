import { useEffect } from 'react';
import { Box, Container, Divider, Heading, Text, VStack } from '@chakra-ui/react';
import Toolbar from '../homePage/Toolbar';
import Footer from '../NewHomePage/footer';

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box width='93%' margin = '0 auto'>
      <Toolbar />
      <Box py={10}>
        <Container maxW="10xl" p={0} mb={3}>
          <VStack spacing={8} align="start">
            {/* Banner */}
            <Box pl={8}>
              <Heading as="h3" size="xl" color="rgb(36, 105, 245)">
                Privacy Policy
              </Heading>
            </Box>

            {/* Privacy Content */}
            <Box bg="white" p={8} borderRadius="lg" boxShadow="md">
              <Heading as="h2" size="lg" mb={4}>
                EFFECTIVE DATE: February 24, 2018
              </Heading>
              <Text mb={4}>
                This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Langistan.com ("us", "we", or "our") operates the www.langistan.com website (the "Service").
              </Text>

              <Heading as="h3" size="md" mb={4}>
                1. INFORMATION COLLECTED
              </Heading>
              <Text mb={4}>
                We collect several different types of information for various purposes to provide and improve our Service to you. Types of Data Collected:
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                Personal Data
              </Heading>
              <Text mb={4}>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data").
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                Usage Data
              </Heading>
              <Text mb={4}>
                We may also collect information about how the Service is accessed and used ("Usage Data").
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                Tracking & Cookies Data
              </Heading>
              <Text mb={4}>
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. You can instruct your browser to refuse all cookies.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                2. USE OF DATA
              </Heading>
              <Text mb={4}>
                Langistan.com uses the collected data for various purposes to:
                <ul>
                  <li>Provide and maintain the Service;</li>
                  <li>Notify you about changes to our Service;</li>
                  <li>Allow you to participate in interactive features;</li>
                  <li>Provide customer care and support;</li>
                  <li>Provide analysis to improve the Service;</li>
                </ul>
              </Text>

              <Heading as="h3" size="md" mb={4}>
                3. TRANSFER OF DATA
              </Heading>
              <Text mb={4}>
                Your information, including Personal Data, may be transferred to India and maintained on computers located outside of your jurisdiction.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                4. DISCLOSURE OF DATA
              </Heading>
              <Text mb={4}>
                Langistan.com may disclose your Personal Data in the good faith belief that such action is necessary to:
                <ul>
                  <li>Comply with a legal obligation;</li>
                  <li>Protect and defend the rights or property of Langistan.com;</li>
                  <li>Prevent or investigate possible wrongdoing;</li>
                </ul>
              </Text>

              <Heading as="h3" size="md" mb={4}>
                5. SECURITY OF DATA
              </Heading>
              <Text mb={4}>
                The security of your data is important to us, but remember that no method of transmission over the Internet is 100% secure.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                6. SERVICE PROVIDERS
              </Heading>
              <Text mb={4}>
                We may employ third-party companies to facilitate our Service and assist us in analyzing how our Service is used.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                7. ANALYTICS
              </Heading>
              <Text mb={4}>
                We may use third-party Service Providers to monitor and analyze the use of our Service.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                8. GOOGLE ANALYTICS
              </Heading>
              <Text mb={4}>
                Google Analytics is a web analytics service that tracks and reports website traffic. You can opt-out by installing the Google Analytics opt-out browser add-on.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                9. LINKS TO OTHER SITES
              </Heading>
              <Text mb={4}>
                Our Service may contain links to other sites that are not operated by us. We recommend reviewing the Privacy Policy of every site you visit.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                10. CHILDREN'S PRIVACY
              </Heading>
              <Text mb={4}>
                Our Service does not address anyone under the age of 18. We do not knowingly collect personal information from children.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                11. CHANGES TO THIS PRIVACY POLICY
              </Heading>
              <Text mb={4}>
                We may update our Privacy Policy. We will notify you of any changes by posting the new Privacy Policy on this page.
              </Text>

              <Heading as="h3" size="md" mb={4}>
                12. CONTACT US
              </Heading>
              <Text mb={4}>
                If you have any questions about this Privacy Policy, please contact us at langistan.com/contact
              </Text>

              <Text fontSize="sm" color="gray.500" textAlign="right">
                Last revised on 11th March, 2019
              </Text>
              <Divider my={8} />
              <Footer />
            </Box>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default Privacypolicy;
