import { HamburgerIcon, LockIcon, TimeIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import useSubscription from 'src/hooks/useSubscription'
import {
  GetCourseLeftMenu,
  setSelectedLesson,
  UpdateLastVisitedPage,
} from 'src/redux/actions'

const CircleIcon = () => (
  <i style={{ color: '#ac8' }} className="fa fa fa-circle-o" />
)
const CheckCircleIcon = () => (
  <i style={{ color: '#ac8' }} className="fa fa fa-check-circle" />
)

const LessonItem = ({
  onLessonClick,
  chapterName,
  lesson,
  chapterId,
  extraInfo,
  lessonIdToSelect,
  userRole,
}) => {
  const { lessonExerciseProgress, selectedLanguage } = useSelector(
    store => store.mainReducer
  )

  const { isSubscriptionActive, hasLangSubscription } = useSubscription()

  return (
    <Button
      padding={0}
      borderRadius={0}
      background={
        lessonIdToSelect === lesson.id
          ? 'var(--chakra-colors-gray-300)'
          : 'transparent'
      }
      color="black"
      width="100%"
      fontSize={'sm'}
      fontWeight="normal"
      onClick={() =>
        onLessonClick({
          chapter: chapterName,
          chapterId,
          lesson,
          unit: extraInfo?.unitName,
        })
      }
    >
      <Box
        flex="1"
        textAlign="left"
        padding="0 12px"
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        width={'80%'}
      >
        {lesson.name}
      </Box>

      {lessonExerciseProgress?.lesson ? (
        lessonExerciseProgress?.lesson === lesson?.id &&
        lessonExerciseProgress?.isCompleted
      ) : lesson.isCompleted ? (
        <Icon as={CheckCircleIcon} />
      ) : (
        <Icon as={CircleIcon} />
      )}
      {!lesson.isUnLock &&
        // !isSubscriptionActive &&
        !hasLangSubscription(selectedLanguage) && selectedLanguage.product !== 'free_product' && userRole !== 'ADMIN' ? <LockIcon margin="0px 8px" /> : <UnlockIcon margin="0px 8px" /> }
    </Button>
  )
}

const ChapterText = ({ chapterName }) => (
  <Box flex="1" textAlign="left" padding="0 12px">
    <Text fontSize="xl">
      <b>{chapterName}</b>
    </Text>
    <Text fontSize="md" marginTop={2}>
      <TimeIcon marginTop={-1} /> 2 min
    </Text>
  </Box>
)
const getChapterItem = ({
  chapter: { chapterId, chapterName, lessons },
  getChapterStatusIcon,
  userRole,
  ...props
}) => (
  <AccordionItem key={chapterId} id={chapterId}>
    <h2>
      <AccordionButton
        _hover={{ bg: 'none' }}
        _focus={{
          boxShadow: 'none',
        }}
        padding="24px 0"
      >
        <ChapterText chapterName={chapterName} />
        {getChapterStatusIcon(lessons)}
        <AccordionIcon margin="0px 6px" />
      </AccordionButton>
    </h2>
    <AccordionPanel padding={0}>
      {lessons.length === 0 ? (
        <p
          style={{
            textAlign: 'center',
          }}
        >
          No Record Found!
        </p>
      ) : (
        <Box flex="1" textAlign="left" flexDirection="column">
          {lessons.length > 0 &&
            lessons.map(lesson => (
              <LessonItem
                key={lesson.id + lesson.name}
                lesson={lesson}
                chapterId={chapterId}
                chapterName={chapterName}
                userRole={userRole}
                {...props}
              />
            ))}
        </Box>
      )}
    </AccordionPanel>
  </AccordionItem>
)
const LeftMenu = ({placement = 'left', userRole, props}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const { courseLeftMenu, selectedLesson } = useSelector(
    store => store.mainReducer
  )
  const history = useHistory()
  const { courseId, unitId, chapterId, lessonId } = useParams()
  const { chapters, extraInfo } = courseLeftMenu

  const lessonIdToSelect = selectedLesson?.lesson?.id
  useEffect(() => {
    const newUnitId = courseLeftMenu?.extraInfo?.unitId ?? false
    let previousLesson = {}
    let nextLesson = {}
    if (newUnitId && newUnitId === unitId) {
      const selectedChapter = chapters.find(
        chapter => chapter.chapterId === chapterId
      )
      const selectedLessonIndex = selectedChapter?.lessons.findIndex(
        lesson => lesson.id === lessonId
      )

      if (selectedLessonIndex !== -1) {
        const selectedLesson = selectedChapter?.lessons[selectedLessonIndex]

        previousLesson = selectedChapter.lessons[selectedLessonIndex - 1]
        nextLesson = selectedChapter.lessons[selectedLessonIndex + 1]

        dispatch(
          setSelectedLesson({
            chapter: selectedChapter?.chapterName,
            chapterId: selectedChapter?.chapterId,
            lesson: selectedLesson,
            unit: extraInfo?.unitName,
            extraInfo: extraInfo,
            previousLesson: previousLesson,
            nextLesson: nextLesson,
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseLeftMenu, unitId])

  useEffect(() => {
    if (isEmpty(courseLeftMenu)) {
      dispatch(GetCourseLeftMenu(unitId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, unitId])

  const onLessonClick = useCallback(
    item => {
      dispatch(setSelectedLesson(item))
      const url = `/introduction/course/${courseId}/chapter/${item.chapterId}/unit/${unitId}/lesson/${item.lesson.id}`
      dispatch(UpdateLastVisitedPage(url))
      history.push(url)
      onClose()
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseLeftMenu.chapters, unitId]
  )

  const getChapterStatusIcon = useCallback(
    lessons => {
      if (lessons && lessons.length > 0) {
        let lessonsStatus = lessons.map(lesson => lesson.isCompleted)
        return lessonsStatus.includes(false) ? (
          <Icon
            color="#ac8"
            as={CircleIcon}
            // className="fa fa fa-circle-o"
          />
        ) : (
          <Icon
            as={CheckCircleIcon}
            // className="fa fa fa-check-circle"
          />
        )
      }
      return <CircleIcon />
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitId]
  )
  const initialChapterIndex = chapters?.findIndex(chapter =>
    chapter.lessons.find(lesson => lesson.id === lessonIdToSelect)
  )

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Open Menu"
        className='menu-icon'
        onClick={onOpen}
        w="50px"
        bg={'none'}
        icon={<HamburgerIcon w={6} h={5} fontSize={'xl'} />}
        {...props}
      />
      <Drawer placement={placement} size="sm" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text fontSize="sm">{extraInfo?.courseName}</Text>
            <Text fontSize="xl">{extraInfo?.unitName}</Text>
          </DrawerHeader>
          <DrawerBody>
            <Input placeholder="Type here..." />
            <Accordion defaultIndex={[initialChapterIndex]} allowToggle>
              {chapters?.map(chapter =>
                getChapterItem({
                  chapter,
                  extraInfo,
                  getChapterStatusIcon,
                  onLessonClick,
                  lessonIdToSelect,
                  userRole,
                })
              )}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default LeftMenu
