import './maincontainer.css'

import { Box, Image, Stack, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import LearnTab from 'src/component/CourseDetail/LearnTab'
import LeftMenu from 'src/component/CourseDetail/LeftMenu'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import {
  setSelectedLesson,
  UpdateLastVisitedPage,
  UpdateUserProgress,
} from 'src/redux/actions'

import { LearnTabFooter } from './LearnTabFooter'
import { isMobile } from './Practice/Util'

const MainContainer = () => {
  const { courseLeftMenu, selectedLesson, loading, selectedLanguage } =
    useSelector(store => store.mainReducer)
  const dispatch = useDispatch()
  const { courseId, unitId, lessonId, chapterId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [progress, setProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [played, setPlayed] = useState(0) // Track progress
  const [isMuted, setIsMuted] = useState(false)
  const playerRef = useRef(null)
  const { loading: userLoading, user: auth } = useUser()
  const { isSubscriptionActive, hasLangSubscription } =
    useSubscription(courseId)

  const blockName = 'introduction-container'

  useEffect(() => {
    function onLessonSelected(item) {
      const url = `/introduction/course/${courseId}/chapter/${item.chapterId}/unit/${unitId}/lesson/${item.lesson.id}`
      dispatch(setSelectedLesson(item))
      if (location.pathname !== url) {
        dispatch(UpdateLastVisitedPage(url))
        history.push(url)
      }
    }

    function selectLessonById(lessonId) {
      if (lessonId && courseLeftMenu?.chapters?.length > 0) {
        let lessonToSelect = {}
        for (let chapter of courseLeftMenu?.chapters) {
          if (chapter.lessons.find(item => item.id === lessonId)) {
            lessonToSelect = {
              chapterId: chapter.chapterId,
              chapter: chapter.chapterName,
              lesson: chapter.lessons.find(item => item.id === lessonId),
              unit: courseLeftMenu?.extraInfo.unitName,
              extraInfo: courseLeftMenu?.extraInfo,
            }
            break
          }
        }
        if (lessonToSelect.lesson?.id) {
          onLessonSelected(lessonToSelect)
        } else {
          let chapter = courseLeftMenu?.chapters[0]
          lessonToSelect = {
            chapterId: chapter.chapterId,
            chapter: chapter.chapterName,
            lesson: courseLeftMenu?.chapters[0]['lessons'][0],
            unit: courseLeftMenu?.extraInfo.unitName,
            extraInfo: courseLeftMenu?.extraInfo,
          }
          onLessonSelected(lessonToSelect)
        }
      } else {
        const item = {
          chapterId: chapterId,
          courseId: courseId,
          unitId: unitId,
          lessonId: lessonId,
        }
        const url = `/introduction/course/${courseId}/chapter/${item.chapterId}/unit/${unitId}/lesson/${item.lessonId}`
        dispatch(setSelectedLesson(item))
        if (location.pathname !== url) {
          dispatch(UpdateLastVisitedPage(url))
          history.push(url)
        }
      }
    }

    selectLessonById(lessonId)
  }, [lessonId, courseLeftMenu, courseId, unitId])

  const onLearnVideoEnd = useCallback(
    record => {
      dispatch(
        UpdateUserProgress({
          course: courseId,
          unit: unitId,
          chapter: record.chapterId,
          lesson: record.lesson.id,
          videoCompleted: true,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseId, unitId]
  )

  const goBackLastPage = () => {
    history.push(`/courseDetails/${courseLeftMenu?.extraInfo.unitId}`) // Navigates to the lesson details page
  }

  const hasSubscription = () =>
    !(
      !loading &&
      !selectedLesson?.lesson?.isUnLock &&
      !hasLangSubscription(selectedLanguage) &&
      auth.role !== 'ADMIN' &&
      !auth.isStarUser
    ) || selectedLanguage?.product === 'free_product'

  const isFourLVideo = () => selectedLesson?.lesson?.videoType === '4L'

  return (
    <>
      <Stack width="1183px" margin="0 auto" className={blockName}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="inroduction_header"
        >
          <Image
            cursor="pointer"
            width="60px"
            height="60px"
            src="/icons/Back.svg"
            alt="back-icon"
            className="back-icon"
            onClick={goBackLastPage}
          />
          <Box>
            <Text fontSize="32px" className="introduction-heading">
              {selectedLesson?.lesson?.name}
            </Text>
          </Box>
          <LeftMenu userRole={auth.role} placement="right" />
        </Stack>
        {!isMobile && (
          <LearnTab
            playerRef={playerRef}
            setProgress={setProgress}
            playingProps={{
              isPlaying,
              setIsPlaying,
              played,
              setPlayed,
              setIsMuted,
              isMuted,
            }}
            onLearnVideoEnd={onLearnVideoEnd}
          />
        )}
      </Stack>
      {isMobile && (
        <LearnTab
          playerRef={playerRef}
          setProgress={setProgress}
          playingProps={{
            isPlaying,
            setIsPlaying,
            played,
            setPlayed,
            setIsMuted,
            isMuted,
          }}
          onLearnVideoEnd={onLearnVideoEnd}
        />
      )}
      {hasSubscription() && (
        <LearnTabFooter
          isFourLVideo={isFourLVideo()}
          playerRef={playerRef}
          videoProgress={progress}
          playingProps={{
            isPlaying,
            setIsPlaying,
            played,
            setPlayed,
            setIsMuted,
            isMuted,
          }}
        />
      )}
    </>
  )
}

export default MainContainer
