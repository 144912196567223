import { Box, Button, Heading, Text } from '@chakra-ui/react'

const btnHover = {
  color: '#fff !important',
  background: 'brand.blue',
}
const Jumbotron = ({redirectToSignUp}) => (
  <Box h={'310px'} className="banner-container" mt={'0px !important'}>
    <Box pl={['10px', '10px', '35px']} mt={['25px', '25px', '60px']}>
      <Heading fontSize={['18px', '18px', '32px']} mb={'10px'} color="#121212">
        Speak with confidence
      </Heading>
      <Text
        fontSize={16}
        lineHeight="24px"
        color={'#000000'}
        maxW={['207px', '207px', '440px']}
      >
        Subscription based interactive online language learning program for
        absolute beginners to advanced level students.
      </Text>
      <Button
        color="#fff"
        mt={5}
        variant="primary"
        _hover={btnHover}
        onClick={redirectToSignUp}
        backgroundColor={'#033CC3'}
        w={['120px', '120px', '220px']}
        h={['40px', '40px', '52px']}
      >
        Get Started
      </Button>
    </Box>
  </Box>
)

export default Jumbotron
