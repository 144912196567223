import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useUser } from 'src/hooks/useUser'

import { isMobile } from './CourseDetail/Practice/Util'
import ImageSlider from './homePage/ImageSlider'
import { imageSx } from './homePage/util'
const { Box, Text, Flex, HStack, VStack, Button } = require('@chakra-ui/react')

const DashboardBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()
  let banners = useSelector(store => store.mainReducer.banners)
  let selectedLanguageId = useSelector(
    store => store.mainReducer.selectedLanguageId
  )
  const { user } = useUser()

  if (banners?.length) {
    banners = banners.filter(item => item.course === selectedLanguageId)
    banners = banners.filter(item => !item.isDeleted)
    if (user?.role !== 'ADMIN') {
      banners = banners.filter(item => item.isPublished)
    }
  }

  if (banners?.length === 0) {
    console.log('No Banner added')
    return <></>
  }
  const getText = (index, key) => {
    const data = banners[index]
    if (data && data[key]) {
      return data[key]
    }
  }

  const onButtonClick = index => {
    const data = banners[index]
    history.push(data?.buttonClickUrl)
  }
  return (
    <Flex
      direction={['column', 'column', 'row']}
      w={'100%'}
      style={{
        marginTop: 0,
      }}
      mb={-35}
    >
      <VStack textAlign={['center', 'left', 'left']} w={['100%']} h={['100%']}>
        <ImageSlider
          px={[0]}
          sx={[imageSx]}
          //borderRadius={'15px'}
          interval={4000}
          slides={banners}
          centerMode={false}
          autoPlay={true}
          width={isMobile ? '100%' : '95%'}
          onChange={index => {
            setActiveIndex(index)
          }}
          onClick={() => {
            onButtonClick(activeIndex)
          }}
        />

        <HStack
          px={[5]}
          py={[10, 2, 1]}
          pos={'absolute'}
          w={['70%', '100%', '45%']}
          left={isMobile ? '0%' : '5%'}
          flexDirection={'column'}
          style={{
            cursor: 'pointer',
          }}
          alignItems={isMobile ? 'center' : 'flex-start'}
          onClick={() => {
            onButtonClick(activeIndex)
          }}
        >
          <Box
            maxH={'200px'}
            minH={'200px'}
            h={'200'}
            onClick={() => {
              onButtonClick(activeIndex)
            }}
          >
            <Text
              textAlign={
                isMobile ? getText(activeIndex, 'textPosition') : 'left'
              }
              fontSize={isMobile ? '25px' : '18px'}
              fontWeight={900}
              fontFamily={'poppins'}
              paddingLeft={'10px'}
              paddingRight={'10px'}
              color={isMobile ? '#2d2d2d' : '#2d2d2d'}
            >
              {getText(activeIndex, 'heading')}
            </Text>
            <Text
              textAlign={
                isMobile ? getText(activeIndex, 'textPosition') : 'left'
              }
              py={4}
              w={'100%'}
              fontSize={isMobile ? '18px' : '14px'}
              fontWeight={400}
              paddingLeft={'10px'}
              paddingRight={'10px'}
              fontFamily={'poppins'}
              style={{
                marginInlineStart: 0,
              }}
              color={isMobile ? '#2d2d2d' : '#2d2d2d'}
            >
              {getText(activeIndex, 'subtext')}
            </Text>
            {getText(activeIndex, 'buttonName') && (
              <HStack
                justifyContent={
                  isMobile
                    ? getText(activeIndex, 'buttonPosition')
                    : 'flex-start'
                }
                px={[3, 3, 3]}
                py={[5, 5, 12]}
              >
                <Button
                  color="#fff"
                  w={getText(activeIndex, 'buttonWidth')}
                  h={isMobile ? '36px' : '40px'}
                  fontSize={'14px'}
                  bg={'brand.blue'}
                  _hover={{
                    background: 'brand.blue',
                  }}
                  borderRadius={5}
                  onClick={() => {
                    onButtonClick(activeIndex)
                  }}
                >
                  {getText(activeIndex, 'buttonName')}
                </Button>
              </HStack>
            )}
          </Box>
        </HStack>
      </VStack>
    </Flex>
  )
}

export default DashboardBanner
