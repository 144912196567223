import axios from 'axios'
import { tokenStore } from 'src/redux/useTokenStore'
import { API_URI } from 'src/util/config'

axios.defaults.withCredentials = true

const axiosInstance = axios.create({
  baseURL: API_URI,
})

let retry = false

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // document.body.classList.add(
    //   isMobile ? 'loading-indicator-mobile' : 'loading-indicator'
    // )
    const store = tokenStore.getState()
    config.headers['Content-Type'] = 'application/json'
    const token = config.token || store.token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    // If the request is for a ZIP file, set the responseType to 'blob'
    if (config.url.includes('.zip')) {
      config.responseType = 'blob'
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

//Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // document.body.classList.remove(
    //   isMobile ? 'loading-indicator-mobile' : 'loading-indicator'
    // )
    if (response?.config?.responseType === 'blob') {
      // If the response is a blob (for ZIP download), return the blob
      return response.data
    } 

    if (response?.data?.results?.data !== undefined) {
      return response.data.results.data
    } else if (response?.data?.error) {
      return Promise.reject(response.data.message)
    } else if (response?.data) {
      return response.data
    } else {
      return Promise.reject('Something went wrong, please try again')
    }
  },
  async function (error) {
    // document.body.classList.remove(
    //   isMobile ? 'loading-indicator-mobile' : 'loading-indicator'
    // )
    const originalRequest = error.config
    if (retry) return
    if (error.response.status === 401) {
    localStorage.clear();
      if (window.location.href.includes('language')) {
        if (!window.location.href.includes('login'))
          window.location.href = `/login?fallbackUrl=${window.location.pathname}`

        return Promise.reject(error)
      }
      
      if (originalRequest.url.includes('refreshToken')) {
        const excludedPages = ['login', 'home', 'privacy-policy', 'terms-and-conditions', 'refund-and-return'];
        const currentPath = window.location.pathname;
        
        if (!excludedPages.some(page => window.location.href.includes(page)) && currentPath !== '/') {
          window.location.href = '/login';
        }
      
        return Promise.reject(error);
      }
      
      if (window.location.href.includes('validateUser')) {
        window.location.href = '/login'

        return Promise.reject(error)
      }
      originalRequest._retry = true
      retry = true

      return axiosInstance.post('/refreshToken').then(res => {
        if (res.status === 200) {
          tokenStore.setState({ token: res.data })
          originalRequest.headers['Authorization'] = 'Bearer ' + res.data
          return axios(originalRequest)
        }
      })
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
