import { Box, Text } from '@chakra-ui/react'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
import LanguagesOffered from 'src/component/homePage/LanguagesOffered'
import LanguageSelection from 'src/component/LanguageSelection'
import HomePageLinguals from 'src/constants/homepage-linguals'

// const WantToSpeak = () => {
//   return isMobile ? (
//     <LanguageSelection>
//       <Text
//         textAlign="center"
//         fontWeight={700}
//         fontSize={['20px', '20px', '32px']}
//         my={'20px'}
//         lineHeight="42px"
//       >
//         {HomePageLinguals.languageOfferTitle}
//       </Text>
//     </LanguageSelection>
//   ) : (
//     <Box mt="40px !important" className="languages-section">
//       <LanguagesOffered />
//     </Box>
//   )
// }
const WantToSpeak = () => (
  <Box mt="40px !important" className="languages-section">
    <LanguagesOffered />
  </Box>
)

export default WantToSpeak
