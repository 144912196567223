export const UIConfig = {
  sideList: {
    levels: 'Levels',
    entertainment: 'Entertainment',
    // analytics: 'Analytics',
    blogs: 'Blogs',
  },
}

export const UIConfigHeader = {
  sideList: {
    // levels: 'Learn',
    // analytics: 'Analytics',
  },
}
