import * as Yup from "yup";

export const generateValidationSchema = (fields) => {
  let schemaShape = {};

  fields.forEach((field) => {
    let rule = Yup.string();

    if (field.name === "email") {
      rule = Yup.string().email("Invalid email format").required("Email is required");
    } else if (field.name === "phone") {
      rule = Yup.string()
        .matches(/^\d+$/, "Phone must contain only numbers")
        .max(10, "Phone number cannot exceed 10 digits")
        .required("Phone is required");
    } else if (field.name === "username") {
      rule = Yup.string().min(5, "Username must be at least 5 characters").required("Username is required");
    }

    schemaShape[field.name] = rule;
  });

  return Yup.object().shape(schemaShape);
};
