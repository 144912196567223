import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducer from './reducer'

const persistConfig = {
  key: 'root', // Key in storage
  storage, // Storage type:default localstorage
}

const persistedReducer = persistReducer(persistConfig, reducer)

const setupStore = preloadedState => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
  })
}

const store = setupStore()
const persistor = persistStore(store)

export { store, persistor }
