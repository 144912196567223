import { useSelector } from 'react-redux'
import { useFlags } from 'src/redux/useFeatureFlags'
import API from 'src/util/api'
import useSWR, { useSWRConfig } from 'swr'

import { useUser } from './useUser'

const stripeSubscriptionFetcher = customerId => url =>
  API.get(`${url}?customerId=${customerId}`)

const rzpSubscriptionFetcher = subscriptionId => url =>
  API.get(`${url}?subscriptionId=${subscriptionId}`)

function getSubscrptionFetcher(user, razorPayEnabled) {
  if (razorPayEnabled) {
    return user?.rzpSubscriptionId
      ? rzpSubscriptionFetcher(user?.rzpSubscriptionId)
      : null
  } else {
    return user?.stripeCustomerId
      ? stripeSubscriptionFetcher(user?.stripeCustomerId)
      : null
  }
}

function getSubscriptionUrl(user, razorPayEnabled) {
  if (razorPayEnabled) {
    return 'get-subscription-rzp'
  }
  return 'get-subscription'
}

export default function useSubscription({ enableRazorPay = false } = {}) {
  // const { RAZORPAY_ENABLED = enableRazorPay } = useFlags()
  const RAZORPAY_ENABLED = enableRazorPay
  const { user } = useUser()
  const {
    selectedLanguage,
    courses,
  } = useSelector(store => store.mainReducer)
  const { cache } = useSWRConfig()
  const ALL_LANG_PROD = 'All languages'

  const selectedLangId = selectedLanguage?.id || JSON.parse(localStorage.getItem("selectedLanguage"))?.id;

  const selectedLanguageData = () => courses.find( course => course.id === selectedLangId)

  const fetcher = getSubscrptionFetcher(user, RAZORPAY_ENABLED)
  const subscriptionUrl = getSubscriptionUrl(user, RAZORPAY_ENABLED)

  const subscriptionData = cache.get(subscriptionUrl)

  const { data, error } = useSWR(
    fetcher && !subscriptionData ? subscriptionUrl : null,
    fetcher
  )

  const checkOldUserPlanExpiry = user => {
    const toDate = new Date(user?.toDate)
    const todayDate = new Date()
    return !isNaN(Date.parse(toDate)) ? todayDate > toDate : true
  }

  if (user && !user.stripeCustomerId && !user.rzpSubscriptionId) {
    return {
      isLoading: false,
      isSubscriptionActive:
        user.enrollments?.length && !checkOldUserPlanExpiry(user),
        isFreeProduct: selectedLanguageData()?.product === 'free_product',
      isOldSubscription: true,
      error: null,
      hasLangSubscription: () => {
        return false
      },
    }
  }

  async function manageSubscrption() {
    if (RAZORPAY_ENABLED) {
      alert('need to add manage subscription')

      return
    }

    if (!user?.stripeCustomerId) {
      return
    }

    const data = {
      customerId: user.stripeCustomerId,
    }
    const res = await API.post('create-billing-session', data)
    window.location.href = res.url
  }

  const respData = data || subscriptionData

  const subscription = getSubscriptionData(respData, RAZORPAY_ENABLED)

  async function cancelSubscrption(subsId) {
    if (RAZORPAY_ENABLED) {
      alert('need to add cancel subscription')
      return
    }

    if (!user?.stripeCustomerId) {
      return
    }

    const res = await API.delete(`cancel-subscription/${subsId || 'asd'}`)
    return res
  }

  function checkPlanExpiry(prodId) {
    const plan = subscription.find(item => item?.plan?.product?.id === prodId)
    const timestampInSeconds = Math.floor(new Date().getTime() / 1000)
    return timestampInSeconds > plan?.current_period_end
  }

  // @params: product ID
  // @return boolean value, subscription has completed successfully or not.

  function checkSubscriptionStatus(prodId) {
    const plan = subscription.find(item => item?.plan?.product?.id === prodId)
    return plan?.status === 'active'
  }

  // @return boolean value, user having subscribed product for selected language
  function hasLangSubscription() {
    const languageData = selectedLanguageData();
    if (languageData && subscription) {
      const subscribedProductIds = subscription.map(
        sbs => sbs?.plan?.product?.id
      )
      const subscribedProductNames = subscription.map(
        sbs => sbs?.plan?.product?.name
      )
      const allLangProd = subscription.find(
        item => item?.plan?.product?.name === ALL_LANG_PROD
      )

      if (
        subscribedProductNames.includes(ALL_LANG_PROD) &&
        checkSubscriptionStatus(allLangProd?.plan?.product?.id)
      )
        return true
      return (
        languageData?.product &&
        subscribedProductIds.includes(languageData?.product) &&
        !checkPlanExpiry(languageData?.product) &&
        checkSubscriptionStatus(languageData?.product)
      )
    }
    return false
  }

  return {
    isLoading: !respData && !error,
    isSubscriptionActive: subscription?.[0]?.status === 'active',
    isFreeProduct: selectedLanguageData()?.product === 'free_product',
    isOldSubscription: false,
    error,
    subscription,
    manageSubscrption,
    cancelSubscrption,
    hasLangSubscription,
    selectedLanguageData,
  }
}

function getSubscriptionData(data, razorPayEnabled) {
  if (razorPayEnabled) {
    return data
  }
  return data?.subscriptions?.data
}
