import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Stack, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import flagIcon from 'src/asset/images/flag.svg'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import {
  GetLessonVideo,
  SetLessonExercises,
  SetLessonExercisesProgress,
  SetLessonVideo,
  SetLessonVocabularies
} from 'src/redux/actions'

import { downloadFile } from '../../util/helpers'
import VideoPlayer from '../4lPlayer'
import MediaSharePanel from '../MediaSharePanel'
import ReportSection from '../ReportSection'
import NoRecordFound from './NoRecordFound'
import { isMobile } from './Practice/Util'
import Unsubscribed from './Unsubscribed'

const ShareReportOptions = ({ selectedLesson, setOpen }) => {
  return (
    <VStack style={{ position: 'fixed', top: '110px', marginRight: '10px' }}>
      <Image
        src={flagIcon}
        alt="Report Issue Langistan"
        boxSize="25px"
        onClick={setOpen}
        cursor={'pointer'}
        className='iconHover'
      ></Image>

      {selectedLesson?.lesson?.isUnLock && <MediaSharePanel showText={false}></MediaSharePanel>}
      {selectedLesson?.lesson?.file && <DownloadIcon className='iconHover' cursor='pointer' name='lesson-info-download-icon' alt='Download lesson file' onClick={() => downloadFile(selectedLesson?.lesson?.file)}/>}
    </VStack>
  )
}

const LearnTab = ({ onLearnVideoEnd, setProgress, playingProps, playerRef }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { isSubscriptionActive, hasLangSubscription } = useSubscription(
    params.courseId
  )
  const { loading: userLoading, user: auth } = useUser()

  const {
    lessonVideo,
    selectedLesson,
    selectedLanguage,
    loading,
    loadingCourse,
  } = useSelector(store => store.mainReducer)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(SetLessonExercises([]))
    dispatch(SetLessonExercisesProgress({}))
    return () => {
      dispatch(SetLessonVocabularies([])) && dispatch(SetLessonVideo({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const videoType = selectedLesson?.lesson?.videoType;
    if (videoType) {
      dispatch(GetLessonVideo(videoType === 'NORMAL' ? selectedLesson?.lesson?.video : selectedLesson?.lesson?.fourLVideo))
    }
    return () => {
      dispatch(SetLessonVideo({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLesson])

  if (
    !(
      selectedLesson &&
      selectedLesson.lesson &&
      (selectedLesson.lesson.video ||
      selectedLesson.lesson.fourLVideo)
    ) &&
    !loading &&
    !loadingCourse
  ) {
    return <NoRecordFound textToDisplay="video" />
  }

  if (
    !loading &&
    !selectedLesson?.lesson?.isUnLock &&
    !hasLangSubscription(selectedLanguage) &&
    auth.role !== 'ADMIN' &&
    !auth.isStarUser && (selectedLanguage.product !== 'free_product')
  ) {
    return <Unsubscribed />
  }

  return (
    <>
      <Stack className="introduction-content">
        {Object.keys(lessonVideo).length !== 0 && (
          <>
            <Box paddingTop={['10px', '10px', '10px']}>
              <div className="video-player_wrapper">
                <VideoPlayer
                  videoSrc={lessonVideo.video}
                  subtitles={lessonVideo?.subtitle}
                  setProgress={setProgress}
                  ref={playerRef}
                  {...playingProps}
                />
              </div>

              <VStack alignItems={'flex-end'}>
                {!isMobile && <ShareReportOptions selectedLesson={selectedLesson} setOpen={setOpen} />}
                {isMobile && (
                  <VStack className='share-panel-mobile' display='none'>
                    <Box p={10} w={'full'}>
                      <Flex
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        w={'100%'}
                      >
                        <Image
                          src={flagIcon}
                          alt="Report Issue Langistan"
                          boxSize="25px"
                          onClick={setOpen}
                          cursor={'pointer'}
                          mr={'30px'}
                        ></Image>

                        <MediaSharePanel showText={false}></MediaSharePanel>
                      </Flex>
                    </Box>
                  </VStack>
                )}
              </VStack>
            </Box>
          </>
        )}
        {open && <ReportSection open={open} setOpen={setOpen}></ReportSection>}
      </Stack>
    </>
  )
}

export default React.memo(LearnTab)
