import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import Languages from './homePage/Languages'

const LanguageSelection = ({ setLangSelected = () => {}, ...props }) => {
  const courses = useSelector(store => store.mainReducer.courses)

  const langSelectionHandlr = lang => {
    localStorage.setItem('langSelectedFromHomePage', JSON.stringify(lang))
    localStorage.setItem('selectedLanguageId', lang.id)
    localStorage.setItem('selectedLanguage', JSON.stringify(lang))
    setLangSelected(lang)
  }

  const renderLanguages = () => {
    return courses.map((item, index) => {
      return (
        index % 2 === 0 && index <= 5 && 
        (
          <HStack gap="50px" key={index}>
            <Languages
              langSelectionHandlr={langSelectionHandlr}
              item={courses[index]}
            />
            <Languages
              langSelectionHandlr={langSelectionHandlr}
              item={courses[index + 1]}
            />
          </HStack>
        )
      )
    })
  }

  return (
    <Box mt={5} textAlign="center">
      {props.children}
      {courses?.length && (
        <Stack gap="25px" alignItems="center">
          {renderLanguages()}
        </Stack>
      )}
    </Box>
  )
}

export default LanguageSelection
