import {
  Button,
  Flex,
  FormControl,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAuth } from 'src/context/AuthContext'
import { EmailField, PasswordField, TextField } from 'src/lib/form/fields'
import { HorizontalSeparator } from 'src/lib/separator'

const checkRequiredErrors = (fieldsName, values, errors) =>
  fieldsName.reduce((prevVal, currentValue) => {
    prevVal[currentValue] = !values[currentValue] ? 'Required' : ''
    return prevVal
  }, errors)

const minPwd = 5;

const RegistrationForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const auth = useAuth()
  const selectedLang =
    (localStorage.getItem('langSelectedFromHomePage') !== 'undefined' && typeof JSON.parse(localStorage.getItem('langSelectedFromHomePage')) ===
      'object' &&
      JSON.parse(localStorage.getItem('langSelectedFromHomePage'))?.course) ||
    null

  const history = useHistory()
  let search = useLocation().search
  search = new URLSearchParams(search)
  const toast = useToast()
  const onSignInClick = () => {
    history.push('/login')
  }
  const randomPwd =
    search.get('username') && search.get('email')
      ? (Math.random() + 1).toString(36).substring(5)
      : ''
  useEffect(() => {
    if (search.get('username') && search.get('email')) {
      sentRequest(
        search.get('username'),
        search.get('email'),
        randomPwd,
        () => {},
        search.get('lang')
      )
    }
  }, [search])

  const sentRequest = async (
    username,
    email,
    password,
    setSubmitting,
    lang
  ) => {
    try {
      const response = await auth.register({
        email,
        username,
        password,
        isNotValidated: true,
        url: window.location.origin,
        registrationSubscriptions: lang,
      })

      if (response) {
        const { token } = response
        window.sessionStorage.setItem('token', token)
        history.push('/pricing')
        setSubmitting(false)
      }
    } catch (error) {
      toast({
        status: 'warning',
        title: error.data?.errors[0]?.msg || error || 'Error',
        position: 'bottom-left',
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Flex flexDirection="column">
      <Formik
        initialValues={{
          username: search.get('username') || '',
          email: search.get('email') || '',
          password: randomPwd,
          confirmPassword: randomPwd,
        }}
        validate={values => {
          const errors = checkRequiredErrors(
            ['username', 'email', 'password', 'confirmPassword'],
            values,
            {}
          )

          if (values.username && !/^[a-zA-Z0-9]{5,30}$/.test(values.username)) {
            errors.username =
              'Must be 5-30 characters, letters or alphanumeric only'
          }
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
          }
          if (values.password && values.password.length && !/^[\s\S]{5,}$/.test(values.password)) {
            errors.password = 'Password are allowed with minimum 5 characters'
          }
          if (values.confirmPassword.length && values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Password and confirm password does not match'
          }

          const hasAnyError =
            Object.values(errors).filter(key => !!key).length > 0

          return hasAnyError ? errors : null
        }}
        onSubmit={(
          { username, email, password, ...values },
          { setSubmitting }
        ) => sentRequest(username, email, password, setSubmitting)}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={0.5}
              mb={7}
            >
              <Text fontSize="md" fontWeight="bold">
                {selectedLang
                  ? `Learn ${selectedLang} now`
                  : 'Sign up to Langistan'}
              </Text>
              <Text fontSize="xs">Create your account here</Text>
            </Flex>
            <Flex gap={18} flexDirection="column">
              <FormControl>
                <TextField name="username" placeholder="Username" />
              </FormControl>
              <FormControl>
                <EmailField name="email" placeholder="Email" />
              </FormControl>
              <PasswordField
                name="password"
                placeholder="Password"
                value={showPassword}
                minLength={minPwd}
                onChange={setShowPassword}
              />
              <PasswordField
                name="confirmPassword"
                placeholder="Confirm Password"
                value={showConfirmPassword}
                onChange={setShowConfirmPassword}
              />
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
                variant="primary"
                width="100%"
                style={{
                  height: '48px',
                }}
              >
                SignUp
              </Button>

              {/* <CustomButton
                noFocus
                background="#4285F4"
                _hover={{ background: '#1369f7' }}
                type="submit"
                isLoading={isSubmitting}
                loadingText="Submitting"
              >
                Sign up
              </CustomButton> */}
              <HorizontalSeparator />
              <Text textAlign="center">
                Already have an account?
                <Link
                  marginLeft={1}
                  color="#3E4CCE"
                  href="#"
                  onClick={onSignInClick}
                >
                  Sign in
                </Link>
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default RegistrationForm
