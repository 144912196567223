import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  InputRightElement,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import { updateUserPassword } from 'src/redux/actions'
import { useFlags } from 'src/redux/useFeatureFlags'
import API from 'src/util/api'
import Config from 'src/util/config'
import swal from 'sweetalert'

import FormField from './FormFieldComponent'
import RzpUserSubscription from './RzpUserSubscription'
import { formFields } from './userProfileFormFields'
import { generateValidationSchema } from './userProfileValidation'
import UserSubscription from './UserSubscription'
import { capitalizeFirstLetter } from 'src/util/helpers'
import { Helmet } from 'react-helmet'
const UserProfile = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(store => store.mainReducer.loading)
  const toast = useToast()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword)
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword)

  const { user: auth, mutateUser } = useUser()
  const { RAZORPAY_ENABLED } = useFlags()

  if (!auth) {
    history.push('/login')
    return null
  }

  // Set initial form values dynamically
  const initialValues = formFields.reduce((acc, field) => {
    acc[field.name] = auth[field.name] || ''
    return acc
  }, {})

  // Handle password update
  const onSubmitPasswordChange = () => {
    setErrorMessage('')
    if (!newPassword || newPassword.length < 5) {
      setErrorMessage('Password must be at least 5 characters long')
      return
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('Confirm password and new password should be the same')
      return
    }

    dispatch(updateUserPassword({ email: auth.email, password: newPassword }))
    setNewPassword('')
    setConfirmPassword('')

    toast({
      title: 'Success',
      description: 'Password changed successfully.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }

  // Handle profile update
  const handleProfileUpdate = async values => {
    try {
      await API.put(`${Config.API_URI}editUser/${auth._id}`, values)
      mutateUser(
        { ...auth, ...values },
        false
      );
      swal('Profile has been updated successfully')
    } catch (err) {
      swal('Error in updating profile')
    }
  }

  // Get activation link
  const onGetActivationLink = async () => {
    toast.closeAll()
    try {
      const response = await API.post(`${Config.API_URI}getActivationLink`, {
        email: auth.email,
        url: window.location.origin,
      })

      toast({
        status: response ? 'success' : 'warning',
        title: response
          ? 'Activation link sent successfully.'
          : 'Unable to get your email address. Please try again later.',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: { fontSize: '14px' },
      })
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error sending activation link.',
        position: 'bottom-left',
        isClosable: true,
        containerStyle: { fontSize: '14px' },
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>User Profile | Langistan</title>
      </Helmet>
      <Flex justifyContent="center">
        <Text fontSize={18} fontWeight="bold" p={5}>
          {`Profile Details: ${capitalizeFirstLetter(auth.username)}`}
        </Text>
      </Flex>
      <Flex w="98%" mx="auto" gap="2" paddingTop="10px">
        <Accordion allowToggle defaultIndex={[0]}>
          {/* Personal Information Section */}
          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w="90vw">
                Personal Information
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Formik
                initialValues={initialValues}
                validationSchema={generateValidationSchema(formFields)}
                onSubmit={handleProfileUpdate}
              >
                {formik => (
                  <Form>
                    {formFields.map((field, key) => (
                      <FormField
                        key={key}
                        {...field}
                        formik={formik}
                        auth={auth}
                      />
                    ))}

                    <Box textAlign="center" p={3}>
                      {auth.isNotValidated && (
                        <Button
                          size="lg"
                          width="300px"
                          isLoading={loading}
                          justifyContent="center"
                          variant="primary"
                          onClick={onGetActivationLink}
                        >
                          Get Account Activation Link
                        </Button>
                      )}
                    </Box>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </AccordionPanel>
          </AccordionItem>

          {/* Change Password Section */}
          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w="90vw">
                Change Password
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <InputGroup>
                <InputLeftAddon
                  p={0}
                  justifyContent="center"
                  children="New Password"
                  w="180px"
                />
                <Input
                  placeholder="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  minLength={5}
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Toggle password visibility"
                    icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={toggleNewPasswordVisibility}
                    variant="ghost"
                  />
                </InputRightElement>
              </InputGroup>
              <InputGroup pt={3}>
                <InputLeftAddon
                  p={0}
                  justifyContent="center"
                  children="Confirm Password"
                  w="180px"
                />
                <Input
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  minLength={5}
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <InputRightElement>
                  <Button
                    size="sm"
                    onClick={() => setShowConfirmPassword(prev => !prev)}
                    variant="ghost"
                    p={2}
                    mt={6}
                  >
                    {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errorMessage && (
                <Text textAlign="center" p={2} color="red">
                  {errorMessage}
                </Text>
              )}
              <Box textAlign="center" p={3}>
                <Button size="lg" justifyContent="center" variant="primary" onClick={onSubmitPasswordChange}>
                  Submit
                </Button>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          {/* Subscription Section */}
          <AccordionItem>
            <AccordionButton>
              <Box textAlign="left" w="90vw">
                Subscription Information
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {RAZORPAY_ENABLED ? (
                <RzpUserSubscription />
              ) : (
                <UserSubscription />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  )
}

export default UserProfile
