import * as React from 'react'
import { useToken } from 'src/hooks/useToken'
import * as auth from 'src/providers/AuthProvider'
import { useTokenStore } from 'src/redux/useTokenStore'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const { token, error } = useToken()
  const { setToken } = useTokenStore()

  const login = React.useCallback(
    form =>
      auth.login(form).then(resp => {
        setToken(resp.token)
        return resp
      }),
    []
  )

  const register = React.useCallback(
    form =>
      auth.register(form).then(resp => {
        setToken(resp.token)
        return resp
      }),
    []
  )

  const logout = React.useCallback(async () => {
    await auth.logout()
    localStorage.clear();
    setToken(null)
  }, [])

  const value = React.useMemo(
    () => ({ token, login, logout, register }),
    [login, logout, register, token]
  )

  if (error) {
    console.log('Error in Auth:', error)
  }

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
