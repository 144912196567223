import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react'
import { WarningIcon } from "@chakra-ui/icons";
import ct from 'countries-and-timezones'
import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
import useSubscription from 'src/hooks/useSubscription'
import API from 'src/util/api'
import useSWR from 'swr'
function PlansPricing({ getStartedOnClick, isLoading, subscriptionData }) {
  const [selectedLang, setSelectedLang] = useState(null)
  const [shouldFetchPlans, setShouldFetchPlans] = useState(false)
  const { auth } = useSelector(store => store.mainReducer)
  const history = useHistory()
  const { hasLangSubscription, selectedLanguageData } = useSubscription()
  const fetcher = url => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const country = ct.getCountryForTimezone(timezone)

    return API.get(`${url}&&countryCode=${country?.id}`)
  }

  const filterOldProducts = products =>
    products.filter(product => product?.metadata?.old_plan !== 'true')

  const productsFetcher = async url => {
    const response = await API.get(`${url}`)
    return filterOldProducts(response.results)
  }

  const { data: products, error } = useSWR('products', productsFetcher, {})
  const localStorageProductId = JSON.parse(
    localStorage.getItem('selectedLanguage')
  )?.product
  const selectedProduct = localStorageProductId

  const { data: plans, error: plansError } = useSWR(
    () =>
      shouldFetchPlans
        ? 'plans?productId=' + selectedLang
        : 'plans?productId=' + selectedProduct,
    fetcher,
    {}
  )

  useEffect(() => {
    if (products) {
      setSelectedLang(selectedProduct)
    }
  }, [products])

  const filteredProducts = useMemo(
    () => products?.filter(product => product.id === selectedLang),
    [products, selectedLang]
  )

  if (error) {
    console.log(error)
    return <Text>Can not load plans</Text>
  }

  if (plansError) {
    console.log(plansError);
    return (
      <Center height="50vh">
        <Box 
          p={6} 
          borderRadius="lg" 
          boxShadow="md" 
          bg="red.50" 
          textAlign="center"
        >
          <Icon as={WarningIcon} boxSize={8} color="blue.500" mb={2} />
          <Text fontSize="lg" fontWeight="bold" color="blue.600">
            No such plans for this Language
          </Text>
        </Box>
      </Center>
    );
  }
  if (!plans && !error && !plansError) return <Text>Loading...</Text>

  const sortedPlans = plans?.sort((a, b) => {
    if (a.unit_amount < b.unit_amount) {
      return -1
    }
    if (a.unit_amount > b.unit_amount) {
      return 1
    }
    return 0
  })

  const getPlanName = plan => {
    if (plan.recurring) {
      return `${plan.recurring.interval_count} ${plan.recurring.interval}`
    }
    return 'N/A'
  }

  const getFormattedPrice = plan => {
    const currencyFromStripe = plan.currency
    const amountFromStripe = plan.unit_amount / 100

    const userFriendlyAmount = new Intl.NumberFormat(
      navigator.language || 'en-US',
      {
        style: 'currency',
        currency: currencyFromStripe,
      }
    ).format(amountFromStripe)

    return userFriendlyAmount
  }

  const fetchProductPricing = (id, product) => {
    setSelectedLang(id)
    setShouldFetchPlans(true)
    localStorage.setItem('selectedLanguage', JSON.stringify(product))
  }

  const redirectSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }

  if (selectedLang === 'free_product') {
    history.push({
      pathname: '/language',
    })
  }

  const renderProduct = product => (
    <Box
      className={`plans ${selectedLang === product.id ? 'active' : ''}`}
      key={product.id}
      onClick={() => fetchProductPricing(product.id, { product: product.id })}
      p={2}
    >
      {product.name}
    </Box>
  )

  return (
    <VStack
      className="plans-pricing-container"
      mt={['39px !important', '39px !important', '85px !important']}
      alignItems={'center'}
    >
      <Heading mb={'25px'} fontSize={['20px', '20px', '32px']}>
        Plans & pricing
      </Heading>
      <Box
        className={isMobile ? 'plans-container-mobile' : 'plans-container'}
        gap={isMobile ? '20px' : undefined}
        mb={isMobile ? '30px !important' : undefined}
      >
        {isMobile ? (
          <VStack justifyContent="space-between">
            {filteredProducts?.map(renderProduct)}
          </VStack>
        ) : (
          <HStack>{filteredProducts?.map(renderProduct)}</HStack>
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={['column', 'column', 'row']}
        gap={3}
        mb={['40px !important', '40px !important', '80px !important']}
        className="pricing-container"
      >
        {sortedPlans?.map(plan => (
          <Box className="pricing" w={['', '', '200px', '250px']}>
            {plan.metadata.discount_badge ? (
              <Box className="save-tag">{plan.metadata.discount_badge}</Box>
            ) : null}
            <Text className="plan-name"> {getPlanName(plan)}</Text>
            <Text>{getFormattedPrice(plan)}</Text>
            <Center mb={'16px'} mt={'8px'}>
              <Divider borderColor="#DAE1EA" />
            </Center>

            <Button
              color="#fff"
              variant="primary"
              backgroundColor={'#033CC3'}
              w={'140px'}
              h={'40px'}
              onClick={
                getStartedOnClick
                  ? () => getStartedOnClick(plan?.id)
                  : redirectSignUp
              }
              isLoading={isLoading}
              disabled={hasLangSubscription()}
            >
              {hasLangSubscription() ? 'Subscribed' : 'Get Started'}
            </Button>
          </Box>
        ))}
      </Box>
    </VStack>
  )
}

export default PlansPricing
