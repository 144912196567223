import { Box, Input, InputGroup, InputLeftAddon, Text, Select } from '@chakra-ui/react'

const valueChecker = (name, auth, formik) => {
  if (name === 'email') return auth.email || ''
  if (name === 'status') return auth.isActive ? 'Active' : 'Disabled'
  return formik.values[name] || ''
}

const FormField = ({
  name,
  formik,
  children,
  isDisabled,
  auth,
  type,
  maxLength,
  minLength,
  pattern,
  options = [],
  ...props
}) => (
  <>
    <InputGroup pt={2}>
      <InputLeftAddon
        p={0}
        justifyContent="center"
        children={children}
        w="100px"
      />
      {type === 'select' ? (
        <Select
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
          isDisabled={isDisabled}
          {...props}
        >
          <option value="">Select {children}</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          placeholder={name}
          name={name}
          value={valueChecker(name, auth, formik)}
          onChange={formik.handleChange}
          isDisabled={isDisabled}
          type={type || 'text'}
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
          {...props}
        />
      )}
    </InputGroup>
    {formik.errors[name] && formik.touched[name] && (
      <Box>
        <Text fontSize={13} m={2} color="red">
          {formik.errors[name]}
        </Text>
      </Box>
    )}
  </>
)

export default FormField
