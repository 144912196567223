import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Button,
  Text,
  VStack
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export default function PaymentSuccess() {
  const history = useHistory()

  const onButtonClick = () => {
    history.push('/language')
  }
  return <VStack mt='100px' justifyContent='center'>
      <CheckCircleIcon w={12} h={12} color="green.400"/>
      <Text textStyle="2xl">Payment Successful</Text>
      <Button
        onClick={onButtonClick}
        variant="primary"
        size={'md'} 
        className={`classic`}>
          Happy Learning!
      </Button>
    </VStack>
}
