import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  defineStyle,
  defineStyleConfig,
  Divider,
  Heading,
  List,
  ListItem,
  OrderedList,
} from '@chakra-ui/react'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
const FrequentlyAskedQuestion = () => {
  // const xl = defineStyle({
  //   border: '10px solid',
  //   borderRadius: 'lg',
  // })

  // const dividerTheme = defineStyleConfig({
  //   sizes: { xl },
  // })
  return (
    <Box
      className="frequently-asked-container"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box w={isMobile ? '46px' : '50px'} mt={isMobile ? 5 : 10} mb={6}>
        <Divider
          orientation="horizontal"
          opacity={1}
          borderColor="#000000"
          borderRadius="10px"
          borderBottomWidth={isMobile ? '4px' : '5px'}
        />
      </Box>

      <Heading
        mb={['20px', '20px', '25px']}
        textAlign="center"
        fontSize={['20px', '20px', '24px']}
      >
        Frequently asked questions
      </Heading>
      <Box width={['93%', '93%', '55%']} margin="0 auto">
        <Accordion allowMultiple>
          <AccordionItem
            borderBottomWidth={'1px'}
            borderTopWidth="0px"
            outline={'none !important'}
          >
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      What is Langistan?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Langistan is a platform dedicated to teaching languages, culture, and arts, with a focus on Asian languages. Founded by Anil Mahato, it began with Hindi learning videos aimed at helping volunteers feel comfortable working in India. The name "Langistan" is derived from the word "Lang," short for "languages," and "Istan," meaning "country" in Persian, symbolizing a land of languages. Over time, Langistan has grown into a vibrant community of learners. Its mission is to make learning Asian languages, culture, and arts fun, easy, and accessible for everyone.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      Who is Langistan for?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Langistan is designed for anyone who wants to learn to speak Hindi fluently and explore Indian culture and arts. It is ideal for individuals with Indian spouses, those of Indian descent, or non-Hindi speaking Indians looking to master the language. The platform is also perfect for visual learners, as the video lessons are engaging and easy to understand. Langistan caters to those who cannot attend regular classes due to busy schedules or lack of local Hindi schools. Whether you're starting from the basics or aiming to advance your skills, Anil's teaching method is accessible and effective, making it suitable for both beginners and intermediate learners.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      What are the subscription plans available?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Langistan currently offers three subscription plans for the Hindi course: 3 months, 6 months, and 1 year. Each subscription provides complete access to all Hindi course materials, including video lessons, quizzes, and updates. While the platform plans to introduce courses in Nepali and Marathi, these will have separate subscription plans. Subscribers to the Hindi course will receive full access to all content and updates related to Hindi only.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      Is there a free trial available?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Currently, Langistan does not offer a free trial. To access the language learning programs, you need to subscribe to one of the available plans.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      How do I subscribe?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  To subscribe, follow these steps:
                  <OrderedList>
                    <ListItem>Click on the "Sign Up" button.</ListItem>
                    <ListItem>Choose the language you want to learn.</ListItem>
                    <ListItem>You will be taken to the pricing plan page. Select the plan that best suits your needs.</ListItem>
                    <ListItem>Complete your payment using a credit or debit card.</ListItem>
                    <ListItem>After successful payment, you will be redirected to your learning dashboard, where you can start your courses.</ListItem>
                  </OrderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      Can I cancel my subscription?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Yes, you can cancel your subscription at any time. To do so:
                  <OrderedList>
                    <ListItem>Go to your profile page, located at the top right corner of the screen.</ListItem>
                    <ListItem>Click on "Manage Subscription."</ListItem>
                    <ListItem>Select "Cancel Subscription."</ListItem>
                  </OrderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                    What types of content are available?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                Langistan offers a range of courses for different language levels: absolute beginners, beginners, intermediate, and advanced. Each course is designed to match the student's proficiency level and includes video lessons, interactive exercises, and quizzes. The content is structured to provide a comprehensive learning experience, guiding students through each level of proficiency.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                    How are the lessons structured?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Langistan's courses are easy to follow and organized by skill levels: absolute beginners, beginners, intermediate, and advanced. You can start from the beginning or choose the level that fits you best. Each course has video lessons that guide you step-by-step. You'll also find exercises and quizzes to practice and check your understanding. Your progress is tracked, so you can easily see how you're doing and what you need to work on. This simple and clear structure makes learning easy and enjoyable, even for busy or older learners.
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem borderBottomWidth={'1px'} borderTopWidth="0px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton fontWeight={'600'} p="18px 5px 18px 8px">
                    <Box as="span" flex="1" textAlign="left">
                      Do I need prior knowledge of Hindi?
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" />
                    ) : (
                      <AddIcon fontSize="12px" />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  No prior knowledge of Hindi is required to start learning with Langistan. Our courses are designed to accommodate everyone, whether you're a complete beginner or already have some knowledge. You can start from the basics if you're new to the language or choose a level that matches your existing skills. It's suitable for all learners!
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
}

export default FrequentlyAskedQuestion
