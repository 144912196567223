import './learntabfooter.css';

import { ArrowBackIcon, ArrowForwardIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, Image } from '@chakra-ui/react'
import { useEffect,useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useUser } from 'src/hooks/useUser'
import { getAdminAppUrl } from 'src/util/getAdminAppUrl';

import FourLControls from './FourLControls';
import { isMobile } from './Practice/Util'

export function LearnTabFooter({videoProgress, playingProps, playerRef, isFourLVideo}) {
  const params = useParams()
  const history = useHistory()
  const { user: auth } = useUser()
  const [width, setWidth] = useState(0);
  const divRef = useRef(null);

  const {
    lessonVideo,
    selectedLesson,
    courseLeftMenu,
  } = useSelector(store => store.mainReducer)

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setWidth(divRef.current.clientWidth);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  const onEditLessonClick = () => {
    if (lessonVideo) {
      window.open(
        getAdminAppUrl() + `app/lessons?_id=${selectedLesson.lesson?.id}`,
        '_blank'
      )
    }
  }
  const getNextLesson = () => {
    if (selectedLesson.nextLesson) return selectedLesson.nextLesson

    const currentChapter = courseLeftMenu.chapters.find(
      item => item.chapterId === selectedLesson.chapterId
    )

    if (currentChapter) {
      const currentLessonIndex = currentChapter.lessons.findIndex(
        lesson => lesson.id === selectedLesson.lesson.id
      )

      if (currentLessonIndex > -1) {
        return currentChapter.lessons[currentLessonIndex + 1]
      }
    }

    return
  }

  const onContinueClick = () => {
    const currentLesson = selectedLesson
    const nextLesson = getNextLesson()
    if (nextLesson) {
      const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${nextLesson.id}`
      history.push(url)
    } else {
      // checking for next chapter
      const currentChapterIndex = courseLeftMenu.chapters.findIndex(
        item => item.chapterId === currentLesson.chapterId
      )
      if (currentChapterIndex !== -1) {
        let nextChapter = courseLeftMenu.chapters[currentChapterIndex + 1]
        if (nextChapter && nextChapter.lessons) {
          const lesson = nextChapter.lessons[0] //moving the first item of next chapter
          const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${lesson.id}`
          history.push(url)
        } else {
          history.push('/language')
        }
      } else {
        history.push('/language')
      }
    }
  }

  const onPracticeClick = () => {
    const lessonData = selectedLesson
    if (lessonData.lesson.linkedExerciseIds.length === 0) {
      onContinueClick()
    } else {
      let url = `${history.location.pathname}/practice`
      url = url.replace('/introduction', '')
      history.push(url)
    }
  }

  const onBackClick = () => {
    history.goBack();
  }
  
  const progressBartransformValue = `translateX(${width}px)`;
  const {isPlaying, setIsPlaying, isMuted, played} = playingProps;

  const renderPlayerControls = () => (
    <>
      <Image
        cursor="pointer"
        src="/icons/backward.svg"
        alt="backward"
        width="25px"
        className="backward"
        onClick={() => playerRef.current.backward()}
      />
      <Image
        cursor="pointer"
        width="35px"
        src={`/icons/${isPlaying ? 'stop.svg' : 'play.svg'}`}
        onClick={() => setIsPlaying(!isPlaying)}
        alt="play"
        className="play"
      />
      <Image
        cursor="pointer"
        width="25px"
        src="/icons/forward.svg"
        alt="forward"
        className="forward"
        onClick={() => playerRef.current.forward()}
      />
    </>
  );

  return (
    <>
      <div className="player-progress" style={{ width: `${videoProgress}%` }} ref={divRef}>
        <input 
          type="range"
          className="progress-bar-fill"
          style={{transform: progressBartransformValue,  zIndex: 2 }}
          id="progress-bar"
          min="0"
          max="100"
          step="any"
          value={played}
          onMouseDown={() => playerRef.current.setSeek(true)}
          onChange={(e) => playerRef.current.handleSeekChange(e)}
          onMouseUp={() => playerRef.current.setSeek(false)} 
        />
        <HStack
          className="inroduction-footer"
          h="70px"
          bgColor="#F2F7FF"
          position="Fixed"
          bottom="0"
          width="100%"
        >
          <Box
            width="1183px"
            margin="0 auto"
            justifyContent="space-between"
            display="flex"
          >
            <Button
              leftIcon={<ArrowBackIcon />}
              onClick={onBackClick}
              variant="secondary"
              size={'md'}
              width={isMobile && auth?.role !== 'ADMIN' ? '100%' : 'auto'}
              className={`classic back-button`}
            >
              Back
            </Button>
            <Box alignItems='center' margin='0 auto' gap="8px" display="flex">
              { isMobile ? renderPlayerControls() :
                <FourLControls isFourLVideo={isFourLVideo} playerRef={playerRef}> {renderPlayerControls()}</FourLControls>
              }
              <Image
                cursor="pointer"
                src={`/icons/${!isMuted ? 'sound.svg' : 'mute.svg'}`}
                width="25px"
                alt="sound"
                className="sound"
                onClick={() => playerRef.current.mute()}
              />
            </Box>
            <Button
              rightIcon={<ArrowForwardIcon />}
              onClick={onPracticeClick}
              variant="secondary"
              size={'md'}
              width={isMobile && auth?.role !== 'ADMIN' ? '100%' : 'auto'}
              className={`classic continue-button`}
            >
              Continue
            </Button>
            {auth?.role === 'ADMIN' && (
              <Button
                onClick={onEditLessonClick}
                rightIcon={<EditIcon />}
                variant="primary"
                size={'lg'}
              >
                Edit
              </Button>
            )}
          </Box>
      </HStack>
      </div>
      
    </>
  )
}
