import { Box, Image, Text } from '@chakra-ui/react'

import generalLanguages from '../../asset/images/Images/general-languages.png'
import { getFileUrl, isMobile } from '../CourseDetail/Practice/Util'

const Languages = ({ langSelectionHandlr, item }) => {
  return (
    <Box
      key={item?.course}
      onClick={() => langSelectionHandlr(item)}
      className="languageContainer"
      textAlign={'center'}
      padding={['', '9px 18px', '15px 20px']}
      width={['', '102px', '105px']}
      height={['', '91px', '100px']}
      bg="#FFFFFF"
      boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
      borderRadius="20px"
      visibility={!item && 'hidden' }
    >
      <Image
        boxSize="28px"
        display={'inline'}
        objectFit="cover"
        src={
          item?.langIcon ? getFileUrl(item?.langIcon || '') : generalLanguages
        }
        alt="Dan Abramov"
      />
      <Text
        mt={isMobile ? 1 : 3}
        fontSize="xs"
        color={'#000000'}
        fontWeight="800"
      >
        {item?.course}
      </Text>
    </Box>
  )
}

export default Languages
