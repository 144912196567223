import './index.css'

import { Box, VStack } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'

import Toolbar from '../homePage/Toolbar'
import Achievments from './achievments'
import Experience from './experience'
import Footer from './footer'
import FrequentlyAskedQuestion from './frequently-asked-questions'
import Jumbotron from './jumbotron'
import OurMethods from './our-methods'
import OurStudents from './our-students'
import VisionMission from './vision-mission'
import WantToSpeak from './want-to-speak'
import WhatWeBelieve from './what-we-believe'
const HomePage = () => {
  const history = useHistory()
  const redirectToSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <VStack className="home-page-container" align="stretch">
      <Helmet>
        <title>Home | Langistan</title>
      </Helmet>
      <Toolbar />
      <Jumbotron redirectToSignUp={redirectToSignUp} />
      <WantToSpeak />
      <OurMethods />
      <Achievments />
      <WhatWeBelieve />
      <VisionMission />
      <OurStudents />
      {/* <PlansPricing /> */}
      <Box
        display="flex"
        flexDirection={['column-reverse', 'column-reverse', 'column']}
      // mt={isMobile && 11}
      >
        <FrequentlyAskedQuestion />
        <Experience redirectToSignUp={redirectToSignUp} />
      </Box>

      <Footer />
    </VStack>
  )
}

export default HomePage
