import './fourLControls.css'

import { Box } from '@chakra-ui/react'

const FourLControls = ({children = '', playerRef, isFourLVideo = false}) => {
  const onClickTranscriptLineControl = (lineControlIndex) => {
    playerRef.current.disableTranscriptLine(lineControlIndex);
  }

  if(!isFourLVideo) {
    return children;
  }

  return (
    <>
        <Box onClick={() => onClickTranscriptLineControl(1)} className="fourL-control fourL-control-1">1</Box>
        <Box onClick={() => onClickTranscriptLineControl(2)} className="fourL-control fourL-control-2">2</Box>
            {children}
        <Box onClick={() => onClickTranscriptLineControl(3)} className="fourL-control fourL-control-3">3</Box>
        <Box onClick={() => onClickTranscriptLineControl(4)} className="fourL-control fourL-control-4">4</Box>
    </>
  )
};

export default FourLControls;
