import { Box, Heading, Image, Text } from '@chakra-ui/react'
import visionMissionImage from 'src/asset/images/vision-mission.svg'

const VisionMission = () => {
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      flexDirection={['row']}
      px="25px"
      py="10px"
      className="vision-mission-container"
    >
      <Box
        display="flex"
        gap="30px"
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        justifyContent="center"
      >
        <Box
          textAlign={['center', 'center', 'initial']}
          flex={1.3}
          p="20px 10px"
          className="vision-mission-item"
        >
          <Heading mb={'10px'} size={'md'}>
            Our Mission
          </Heading>
          <Text>
            Making language learning accessible, effective, and enjoyable for
            all, while creating job opportunities and promoting personal and
            professional growth for our students & teachers.
          </Text>
        </Box>
        <Box
          textAlign={['center', 'center', 'initial']}
          flex={1}
          p="20px 10px"
          className="vision-mission-item"
        >
          <Heading mb={'10px'} size={'md'} fontWeight={800}>
            Our Vision
          </Heading>
          <Text>
            Creating a world where language learning is a tool for fostering
            unity, embracing diversity, and building empathy across cultures.
          </Text>
        </Box>

        <Box flex={2} justifyContent="flex-end" display={'flex'}>
          <Image src={visionMissionImage} />
        </Box>
      </Box>
    </Box>
  )
}

export default VisionMission
