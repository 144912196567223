import React from 'react';
import { Box, Button, Heading, Text, VStack, useBreakpointValue, Link } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons'
import { useHistory } from "react-router-dom";


const PaymentFailed = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory()
  const handleBack = () => history.goBack()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 72px)"
      bgColor="gray.50"
    >
      <VStack spacing={6} textAlign="center" maxWidth="500px" w="100%" p={6}>
        <InfoIcon boxSize={isMobile ? 16 : 20} color="#ff5454" />
        <Heading size="lg" color="gray.800">
          Payment Failed
        </Heading>
        <Text fontSize="lg" color="gray.600">
          Something went wrong with your payment. Please try again later.
        </Text>
        <Button
          colorScheme="blue"
          variant="solid"
          size="lg"
          onClick={handleBack}
        >
          Retry Payment
        </Button>
        <Text color="gray.500" fontSize="sm">
          If the problem persists, please contact <Link href="mailto:support@langistan.com" _hover={{ textDecoration: 'underline', color: 'blue.600' }}>support@langistan.com</Link>.
        </Text>
      </VStack>
    </Box>
  );
};

export default PaymentFailed;
