import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'

const Achievments = () => {
  return isMobile ? (
    <Box bgColor={"#121212"} p={"20px 50px"} className="achievments-container-mobile">
      <VStack color={"#fff"}>
        <Box className="achievments-mobile">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>Lesson Delivered</Text>
        </Box>
        <Box className="achievments-mobile">
          <Text mb={'5px'}>135,000+</Text>
          <Text>Students on web & social media</Text>
        </Box>
        <Box className="achievments-mobile">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>Interactive courses</Text>
        </Box>
        <Box className="achievments-mobile">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>4 Line Videos</Text>
        </Box>
        <Box className="achievments-mobile">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>LRSW practice interactions</Text>
        </Box>
      </VStack>
    </Box>
  ) : (
    <HStack className="achievments-container">
      <Box display={'flex'} flexDirection="row" py={0}>
        <Box className="achievments">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>Lesson Delivered</Text>
        </Box>
        <Box className="achievments">
          <Text mb={'5px'}>135,000+</Text>
          <Text>Students on web & social media</Text>
        </Box>
        <Box className="achievments">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>Interactive courses</Text>
        </Box>
        <Box className="achievments">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>4 Line Videos</Text>
        </Box>
        <Box className="achievments">
          <Text mb={'5px'}>23,107,355+</Text>
          <Text>LRSW practice interactions</Text>
        </Box>
      </Box>
    </HStack>
  )
}

export default Achievments
