import 'src/web.config'
import './index.css'

import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { PersistGate } from 'redux-persist/integration/react';

import { AppContainer } from './Container/AppContainer'
import { AuthProvider } from './context/AuthContext'
import { store, persistor} from './redux/store'
import theme from './theme'

ReactDOM.render(
  <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false }}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
              <AppContainer />
            </AuthProvider>
          </BrowserRouter>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </SWRConfig>,
  document.getElementById('root')
)
