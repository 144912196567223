import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import ct from 'countries-and-timezones'
import React, { useState } from 'react'

import { useHistory } from 'react-router'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import API from 'src/util/api'
import useSWR from 'swr'

import PlansPricing from '../component/plans-pricing'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
const fetcher = url => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const country = ct.getCountryForTimezone(timezone)

  return API.get(`${url}?countryCode=${country?.id}`)
}

export default function Pricing() {
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false)
  const { isPaymentError, setIsPaymentError } = useState(false)
  const { data, error } = useSWR('plans', fetcher, {})
  const { user } = useUser()
  const history = useHistory()
  const { selectedLanguage } = useSelector(store => store.mainReducer)
  const {
    isFreeProduct,
    isOldSubscription,
    manageSubscrption,
    hasLangSubscription,
    subscription,
  } = useSubscription()

  if (error) {
    console.log(error)
    return <Text>Can not load plans</Text>
  }

  if (!data && !error) return <Text>Loading...</Text>

  function getPlanName(plan) {
    if (plan.recurring) {
      return `${plan.recurring.interval_count} ${plan.recurring.interval}`
    }
    return 'N/A'
  }

  function getFormattedPrice(plan) {
    const currencyFromStripe = plan.currency
    const amountFromStripe = plan.unit_amount / 100

    const userFriendlyAmount = new Intl.NumberFormat(
      navigator.language || 'en-US',
      {
        style: 'currency',
        currency: currencyFromStripe,
      }
    ).format(amountFromStripe)

    return userFriendlyAmount
  }

  async function createSubscrption(priceId) {
    try {
      setIsCreatingCheckoutSession(true);
  
      const res = await API.post('create-checkout-session', {
        email: user.email,
        customerId: user.stripeCustomerId,
        priceId,
        origin: window.location.origin,
      });
  
      if (res?.url) {
        window.location.href = res.url;
      } else {
        console.error('Failed to retrieve checkout URL');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
    } finally {
      setIsCreatingCheckoutSession(false);
    }
  }

  if (isFreeProduct) {
    return (
      <Stack spacing="4" width="full" alignItems={'center'} mt="20">
        <Text fontWeight={'bold'} fontSize="2xl">
          This product is free for you.
        </Text>
        <Button
          variant="primary"
          width="unset"
          onClick={() =>
            history.push({
              pathname: '/language',
            })
          }
        >
          Happy Learning!
        </Button>
      </Stack>
    )
  }

  if (hasLangSubscription(selectedLanguage)) {
    return (
      <Stack spacing="4" width="full" alignItems={'center'} mt="20">
        <Text fontWeight={'bold'} fontSize="2xl">
          You have active subscription
        </Text>
        {!isOldSubscription && (
          <Button variant="primary" width="unset" onClick={manageSubscrption}>
            Manage Subscription
          </Button>
        )}
      </Stack>
    )
  }

  const sortedPlans = data.sort((a, b) => {
    if (a.unit_amount < b.unit_amount) {
      return -1
    }
    if (a.unit_amount > b.unit_amount) {
      return 1
    }
    return 0
  })

  const OldPricing = () => (
    <Flex
      direction={['column', 'column', 'row']}
      backgroundColor="#F4F4FF"
      justifyContent="center"
      paddingY="5"
      gap={2}
      mt="20"
    >
      <Helmet>
        <title>Pricing | Langistan</title>
      </Helmet>
      {sortedPlans.map(plan => {
        return (
          <Box
            key={plan.id}
            bgColor="white"
            boxShadow="0px 3px 6px #00000029"
            padding="15px"
            position={'relative'}
          >
            {plan.metadata.discount_badge ? (
              <Box
                p="2"
                bg="brand.blue"
                textAlign="center"
                color="white"
                borderRadius="8px"
                display="inline-block"
                position="absolute"
                left="50%"
                transform={'translate(-50%, -90%)'}
              >
                {plan.metadata.discount_badge}
              </Box>
            ) : null}
            <VStack>
              <Text fontSize="28px" fontWeight="semibold">
                {getPlanName(plan)}
              </Text>
              <Text fontSize="28px" fontWeight="semibold" color="#2469F5">
                {getFormattedPrice(plan)}
              </Text>
              <Text fontSize="14px" fontWeight="semibold">
                Full access of complete library
              </Text>
              <Stack
                fontSize="12px"
                fontWeight="medium"
                mt="8px"
                spacing="4px"
                width={'full'}
              >
                <Text>27+ course</Text>
                <Text>1000+ video lessons</Text>
                <Text>3,000+ Exercises</Text>
                <Text>Access to all features</Text>
                <Text>Weekly new content updates</Text>
              </Stack>
              <Button
                variant="outline"
                mx="auto"
                onClick={() => createSubscrption(plan.id)}
                isLoading={isCreatingCheckoutSession}
              >
                Get Started
              </Button>
            </VStack>
          </Box>
        )
      })}
    </Flex>
  )

  return (
    <Stack justifyContent="center">
      {!process.env.REACT_APP_NEW_PRICING ? (
        <OldPricing />
      ) : (
        <PlansPricing
          getStartedOnClick={createSubscrption}
          subscriptionData={subscription}
          isLoading={isCreatingCheckoutSession}
        />
      )}
      {isPaymentError && (
        <PaymentFailedModal onClose={() => setIsPaymentError(false)} />
      )}
    </Stack>
  )
}

function PaymentFailedModal({ onClose }) {
  return (
    <>
      <Modal isOpen onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Failed</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Some error occured during payment. Please try again
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
