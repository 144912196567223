import { Box, Button, HStack, Image, useDisclosure } from '@chakra-ui/react'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import menu from 'src/asset/images/hamburger.svg'
import logo from 'src/asset/images/logoBeta.svg'

// import logo from 'src/asset/images/xmas-logo.svg'
import { isMobile } from '../CourseDetail/Practice/Util'
import SideDrawer from './Drawer'

const btnHover = {
  color: '#fff !important',
  background: 'brand.blue',
}

const Toolbar = () => {
  const history = useHistory()
  const onLogin = () => {
    history.push({
      pathname: '/login',
      state: {
        comingFromHome: true,
      },
    })
  }
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  const height = '40px'
  const width = isMobile ? '74px' : '110px'
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const drawerProps = {
    width,
    height,
    btnHover,
    onLogin,
    isOpen,
    onClose,
    btnRef,
  }

  const onLogoClick = () => {
    history.push('/home')
  }

  return (
    <Box
      h="60px"
      py={5}
      px={['5px', '5px', '28px']}
      boxShadow={'0px 3px 8px #00000029'}
      position={'sticky'}
      top={0}
      zIndex={1000}
      bg={'#fff'}
    >
      <HStack h={4} justifyContent={'space-between'} flexDir={'row'}>
        {/* {isMobile && (
          <Image
            ml={2}
            boxSize="20px"
            src={menu}
            ref={btnRef}
            onClick={onOpen}
          />
        )} */}
        {/* <SideDrawer {...drawerProps} /> */}
        <Image
          src={logo}
          width={isMobile ? '116px' : '165px'}
          onClick={onLogoClick}
          _hover={{ cursor: 'pointer' }}
          sx={{
            objectFit: 'contain',
            height: '65px',
            marginTop: '10px',
            marginLeft: '10px',
          }}
        ></Image>
        <HStack py={5}>
          {/* {isMobile || (
            <Button
              color="#707070"
              w={width}
              h={height}
              fontWeight={600}
              fontSize={'16px'}
              _hover={btnHover}
              style={{
                border: '1px solid #1C5EE9',
                borderRadius: '5px',
                backgroundColor: '#fff',
                color: '#1C5EE9',
              }}
              onClick={onLogin}
            >
              Login
            </Button>
          )} */}

          <Box>
            <Button
              color="#707070"
              w={['80px', '80px', '160px']}
              h={['30px', '30px', '42px']}
              fontSize={isMobile ? 14 : 16}
              fontWeight={600}
              _hover={btnHover}
              borderRadius={['2px', '2px', '5px']}
              style={{
                border: '1px solid #1C5EE9',
                backgroundColor: '#fff',
                color: '#1C5EE9',
                marginRight: '10px',
              }}
              onClick={onLogin}
            >
              Login
            </Button>
            <Button
              color="#fff"
              variant="primary"
              fontWeight={500}
              fontSize={isMobile ? 14 : 16}
              mr={isMobile && 1}
              w={['80px', '80px', '160px']}
              h={['30px', '30px', '42px']}
              //bg={btnBg}
              borderRadius={['2px', '2px', '5px']}
              _hover={btnHover}
              onClick={onSignUp}
            >
              Join Us
            </Button>
          </Box>
        </HStack>
      </HStack>
    </Box>
  )
}

export default Toolbar
