import { useToken } from 'src/hooks/useToken'
import { useUser } from 'src/hooks/useUser'

import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes'

export const AppContainer = () => {
  const { loading, token } = useToken()
  const { isLoading: userLoading } = useUser()

  if (loading || userLoading) return null

  return token && token !== 'null' ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />
}
