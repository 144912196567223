import { Helmet } from 'react-helmet'
import { Box, Container, Heading, Text, Button, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <>
    <Helmet>
      <title>404 | Langistan</title>
    </Helmet>
    <Box
      bg="gray.50"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      p={3}
      overflow="hidden"
    >
      <Container maxW="md">
        <Heading as="h1" size="2xl" mb={4}>
          404: The page you are looking for isn’t here
        </Heading>
        <Text fontSize="lg" mb={6}>
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation or click the button below to go back to the homepage.
        </Text>
        <Box textAlign="center" mb={6}>
          <Image
            alt="Page not found"
            src="/static/images/undraw_page_not_found_su7k.svg"
            mt={6}
            display="inline-block"
            maxW="100%"
            width={560}
          />
        </Box>
        <Button
          as={Link}
          to="/"
          size="lg"
          px={8}
          maxW="100%"
          whiteSpace="normal"
          bg="#2469F5"
          color="white"
          _hover={{ bg: '#1e5edc' }}
        >
          Go to Homepage
        </Button>
      </Container>
    </Box>
  </>
)

export default NotFound