import { useEffect } from 'react';
import { Box, Container, Divider, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import Toolbar from '../homePage/Toolbar';
import Footer from '../NewHomePage/footer';

const RefundAndReturn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <Box width='93%' margin='0 auto'>
      <Toolbar />
      <Box py={10}>
        <Container maxW="10xl" p={0} mb={3}>
          <VStack spacing={8} align="stretch">
            <Box pl={8}>
              <Heading as="h3" size="xl" color="rgb(36, 105, 245)">
                Return and Refund Policy
              </Heading>
            </Box>

            <Box bg="white" p={8} boxShadow="md" borderRadius="lg">
              <Text fontSize="sm" color="gray.500" textAlign="right">
                Last updated: August 12, 2022
              </Text>

              <Text mb={4}>
                Thank you for shopping at Langistan Language School website and Langistan app.
                If, for any reason, you are not completely satisfied with a purchase, we invite you to review our policy on refunds and returns. 
                The following terms are applicable for any products or subscriptions that you purchased with us.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Interpretation and Definitions</Heading>
              <Heading as="h4" size="sm" mb={2}>Interpretation</Heading>
              <Text mb={4}>
                The words with capitalized initial letters have meanings defined under the following conditions. The definitions apply regardless of singular or plural usage.
              </Text>
              
              <Heading as="h4" size="sm" mb={2}>Definitions</Heading>
              <Text mb={4}>
                <strong>Application:</strong> The software program provided by the Company, named Langistan.
                <br />
                <strong>Company:</strong> Cocoma Digital Private Limited, UNIT NO 611 RELIABLES PRIDE, ANAND NAGAR, OPP HEERA PANNA JOGESHWARI WEST, MUMBAI, MH 400102, INDIA.
                <br />
                <strong>Goods:</strong> Items offered for sale on the Service.
                <br />
                <strong>Orders:</strong> A request by You to purchase Goods from Us.
                <br />
                <strong>Service:</strong> The Application, Website, or both.
                <br />
                <strong>Website:</strong> Langistan Language School, accessible from https://beta.langistan.com
                <br />
                <strong>You:</strong> The individual or entity accessing or using the Service.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Your Order Cancellation Rights</Heading>
              <Text mb={4}>
                You are entitled to cancel Your Order within 14 days without giving any reason. The deadline for cancellation is 14 days from the date You received the Goods or when a third party takes possession of the product.
                To exercise Your right of cancellation, inform Us via:
                <br />
                - Email: support@langistan.com
                <br />
                - Our contact page: https://langistan.com/contact-us
                <br />
                Refunds will be processed within 14 days via the same payment method used for the purchase, without any fees.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Conditions for Returns</Heading>
              <Text mb={4}>
                To be eligible for a return:
                <br />
                - Goods/Subscriptions must have been purchased within the last 30 days.
                <br />
                - Goods/Subscriptions must be in their original packaging.
              </Text>
              <Text mb={4}>
                The following items cannot be returned:
                <br />
                - Personalized or custom-made items.
                <br />
                - Items that deteriorate rapidly or have expired.
                <br />
                - Sealed items that are not suitable for return due to hygiene reasons.
                <br />
                - Items mixed inseparably with others.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Returning Goods/Subscriptions</Heading>
              <Text mb={4}>
                You are responsible for the return shipping costs and risks. Returns should be sent to:
                <br />
                <strong>101, Shourie Complex, Behind Bombay Bazar, JP Road, Andheri West, Mumbai, Maharashtra, India, 400058</strong>
                <br />
                We recommend using an insured and trackable mail service. Refunds will not be issued without proof of return delivery.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Gifts</Heading>
              <Text mb={4}>
                If the Goods/Subscriptions were marked as a gift when purchased and shipped directly to You, You will receive a gift credit for the value of the return. If the gift giver had the Order shipped to themselves first, refunds will be sent to the gift giver.
              </Text>

              <Heading as="h2" size="lg" mb={4}>Contact Us</Heading>
              <Text>
                If you have any questions about our Return and Refund Policy, contact us:
                <br />
                - By email: support@langistan.com
                <br />
                - Through our website: https://langistan.com/contact-us
              </Text>

              <Divider my={8} />
              <Footer />
            </Box>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default RefundAndReturn;
