export const imageSx = {
  width: '250px',
  borderRadius: '15px',
}
export const imagemd = {
  width: '300px',
  borderRadius: '15px',
}
export const imagelg = {
  width: '350px',
  borderRadius: '15px',
}
export const h1Style = {
  color: '#2469F5',
  fontSize: '20px',
  fontWeight: 900,
  letterSpacing: '2px',
}
export const textPanelWidth = ['90%', '100%', '50%']
export const imageFlex = ['1 0 40%', '1 0 35%', 'initial']

export const hyperTextColor = 'inherit'
export const fontMain = ['18px', '18px', '24px']

export const fontSecond = ['12px', '14px', '16px']
export const bannerText = {
  0: {
    main: 'Speak Hindi from day 1',
    nextText:
      'We kept our lesson in the order of high frequency to low frequency, to have maximum use of what you learnt.',
    buttonText: 'Get Started',
  },
  1: {
    main: 'Learn to speak hindi step by step with our level-based learning',
    nextText:
      'weather you are a beginner or an intermediate student, we got 700 lesson & 3,500 activities to practice',
    buttonText: 'Get Started',
  },
  2: {
    main: 'Speed-up your Hindi learning with our special 4L videos',
    nextText:
      'These 4L videos are so powerful speed-up your Hindi learning with our special 4l videos.',
    buttonText: 'Get Started',
  },
  3: {
    main: 'Memorize new words and be a part of a fascinating learning experience',
    nextText:
      "Don't just learn Hindi but expand your vocabulary and word phrases and master the language",
    buttonText: 'Get Started',
  },
  4: {
    main: 'Learn with real-life conversations, get familiar with the language',
    nextText:
      'Lean Hindi with India families, as we give you a glimpse into our rich culture.',
    buttonText: 'Get Started',
  },
}

export const btnBg = 'brand.blue'
export const btnHover = {
  color: '#fff',
  background: 'brand.blue',
}
