export const formFields = [
  {
    id: 1,
    name: 'username',
    children: 'Username',
    type: 'text',
    maxLength: 50,
  },
  {
    id: 2,
    name: 'email',
    children: 'Email',
    type: 'email',
    isDisabled: true,
  },
  {
    id: 3,
    name: 'phone',
    children: 'Phone',
    type: 'tel',
    maxLength: 10,  
    pattern: '[0-9]{10}', 
  },
  {
    id: 4,
    name: 'city',
    children: 'City',
    type: 'text',
    maxLength: 50,
  },
  {
    id: 5,
    name: 'state',
    children: 'State',
    type: 'text',
    maxLength: 50,
  },
  {
    id: 6,
    name: 'country',
    children: 'Country',
    type: 'text',
    maxLength: 50,
  },
  {
    id: 7,
    name: 'status',
    children: 'Status',
    isDisabled: true,
  },
]
