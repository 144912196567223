import { useEffect } from 'react';
import { Box, Container, Divider, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import Toolbar from '../homePage/Toolbar';
import Footer from '../NewHomePage/footer';

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])  
  return (
    <Box width='93%' margin = '0 auto'>
      <Toolbar />
      <Box py={10}>
        <Container maxW="10xl" p={0} mb={3}>
          <VStack spacing={8} align="stretch">
            {/* Banner Section */}
            <Box pl={8}>
              <Heading as="h3" size="xl" color="rgb(36, 105, 245)">
                Terms & Conditions
              </Heading>
            </Box>

            {/* Content Section */}
            <Box bg="white" p={8} boxShadow="md" borderRadius="lg">
              <Heading as="h2" size="lg" mb={4}>
                INTRODUCTION - EDUCATION THROUGH THE WEB
              </Heading>
              <Text mb={4}>
                We place education as a powerful tool in today’s technology-driven and fast-paced world. We believe in creating a world where everyone has access to knowledge and learning. Langistan.com adheres to its mission of providing quality education through affordable online tutorial services.
              </Text>

              <Heading as="h2" size="lg" mb={4}>
                ABOUT US
              </Heading>
              <Text mb={6}>
                Langistan.com is a team of dedicated, hardworking, and reliable tutors who place learning above everything else. Formed in 2018 as a response to the growing need for non-traditional learning that promotes self-discovery, knowledge construction, group-based discussions, transformational, and independent learning.
              </Text>

              <Heading as="h2" size="lg" mb={4}>
                FAQ
              </Heading>
              <Stack spacing={4} mb={6}>
                <Box>
                  <Heading as="h4" size="sm" mb={2}>
                    Q: What are the requirements for the computer unit (PC/MAC) should I meet?
                  </Heading>
                  <Text>
                    Your computer must meet the following system requirements: Windows XP (w/Service Pack 3), Vista (w/Service Pack 2), or Windows 7 (w/Service pack 1); 2.0 GB of system memory (RAM); Single Core Processor at 2.4 GHz; At least DSL internet connection; Microsoft Internet Explorer 7.0 or higher.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h4" size="sm" mb={2}>
                    Q: What are the available time slots for the online tutorial facility?
                  </Heading>
                  <Text>
                    Langistan.com is available any time, every day, even on weekends and holidays.
                  </Text>
                </Box>

                <Box>
                  <Heading as="h4" size="sm" mb={2}>
                    Q: What subjects can I enroll in?
                  </Heading>
                  <Text>
                    We offer tutorial services currently in Hindi and soon we will include other languages as well.
                  </Text>
                </Box>
              </Stack>

              <Heading as="h2" size="lg" mb={4}>
                TERMS OF AGREEMENTS
              </Heading>

              <Heading as="h4" size="sm" mb={2}>
                1. Definitions
              </Heading>
              <Text mb={4}>
                Langistan.com is an online language learning program offered to its clients referred to as “the student”. The Services refer to the permission to log on to the portal, online tutorial, and customer support.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                2. Scope of Service
              </Heading>
              <Text mb={4}>
                We will connect with our students through our registered software and online portal found in Langistan.com. The Services we provide shall be available 24 hours, seven days a week, 365 days a year.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                3. Privacy Policy
              </Heading>
              <Text mb={4}>
                Your registered account will be verified through your registered email address. We respect the Student's right to privacy and will not disclose or sell personal identification details.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                4. Payments
              </Heading>
              <Text mb={4}>
                You can choose packages from our membership plans. Payments can be made through PayPal or wire transfer.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                5. User Content
              </Heading>
              <Text mb={4}>
                Any material, information, or communication you upload using our facility will be treated as non-confidential and non-proprietary.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                6. Modifications of the Service
              </Heading>
              <Text mb={4}>
                We may add, modify or disable some of the existing features in the online classroom. We reserve the right to change or discontinue the service to improve overall site features.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                7. Restrictions
              </Heading>
              <Text mb={4}>
                You are expressly restricted from publishing, selling, sublicensing, publicly performing, or using this Website in any harmful way.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                8. Variation of Terms
              </Heading>
              <Text mb={4}>
                Langistan.com may revise these Terms at any time, and you are expected to review them regularly.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                9. Assignment
              </Heading>
              <Text mb={4}>
                Langistan.com may assign its rights and obligations under these Terms without notification. You are not permitted to assign your rights or obligations.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                10. Entire Agreement
              </Heading>
              <Text mb={4}>
                These Terms constitute the entire agreement between Langistan.com and you in relation to the use of this Website.
              </Text>

              <Heading as="h4" size="sm" mb={2}>
                11. Governing Law & Jurisdiction
              </Heading>
              <Text>
                These Terms will be governed by and construed in accordance with the laws of the State of Rajasthan, India.
              </Text>
              <Text fontSize="sm" color="gray.500" textAlign="right">
                Last revised on 11th March, 2019
              </Text>
              <Divider my={8} />
              <Footer />
            </Box>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default TermsConditions;
