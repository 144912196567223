import { Box, Image, List, ListItem, Text } from '@chakra-ui/react'
import fourLIcon from 'src/asset/images/4L.svg'
import listenspeakreadIcon from 'src/asset/images/listen-speak-read.svg'
import paretoLawIcon from 'src/asset/images/pareto-law.svg'
import spacedIcon from 'src/asset/images/spaced.svg'
import ourmethodsVideo from 'src/asset/videos/ourMethods.mp4'
import { videoScroll } from 'src/util/helpers'
const OurMethods = () => {
  window.addEventListener('load', videoScroll)
  window.addEventListener('scroll', videoScroll)
  return (
    <Box
      p={'0px 36px'}
      mt="40px !important"
      mb="40px !important"
      className="our-methods-container"
    >
      <Text
        textAlign="center"
        fontWeight={800}
        my={['20px', '20px', '40px']}
        fontSize={['20px', '20px', '32px']}
        lineHeight="36px"
      >
        Our methods
      </Text>
      <Box
        py="20px"
        display={'flex'}
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={['', '', '50%', '']}
          mr={['0px', '0px', '30px', '0px']}
          className="our-methods-image"
        >
          <video
            autoplay="autoplay"
            loop="loop"
            muted
            defaultMuted
            playsinline
            oncontextmenu="return false;"
            preload="auto"
          >
            <source src={ourmethodsVideo} type="video/mp4" />
          </video>
        </Box>
        <Box
          width={['100%', '100%', '50%', '30%']}
          mt={['25px', '25px', '0px']}
          className="our-methods"
        >
          <List spacing={'35px'}>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="our-methods-icon" src={fourLIcon} />4 Line
                System of language learning
              </Text>
            </ListItem>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="our-methods-icon" src={listenspeakreadIcon} />
                Usage of listening, speaking, reading & writing
              </Text>
            </ListItem>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="our-methods-icon" src={paretoLawIcon} />
                Pareto’s Law
              </Text>
            </ListItem>
            <ListItem px="10px" py="5px">
              <Text
                fontSize={'16px'}
                fontWeight="600"
                lineHeight="26px"
                gap={[2, 2, 6]}
                alignItems="center"
                textAlign={['center', 'center', 'initial']}
                flexDirection={['column', 'column', 'row']}
                display={'flex'}
              >
                <Image className="our-methods-icon" src={spacedIcon} />
                Spaced repetition
              </Text>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default OurMethods
