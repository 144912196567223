import './videoplayer.css'

import { Box, HStack, Stack, VStack } from '@chakra-ui/react'
import React, { forwardRef, useRef, useState } from 'react'
import { useImperativeHandle } from 'react'
import ReactPlayer from 'react-player'
import { toSeconds } from 'src/util/helpers'

import FourLControls from '../CourseDetail/FourLControls'
import { isMobile, transformTranscript } from '../CourseDetail/Practice/Util'

const VideoPlayer = forwardRef(
  (
    {
      videoSrc,
      subtitles,
      setProgress,
      isPlaying,
      isMuted,
      setIsMuted,
      setIsPlaying,
      played,
      setPlayed,
    },
    ref
  ) => {
    const [currentSubtitle, setCurrentSubtitle] = useState('')
    const playerRef = useRef(null)
    const [transcriptLinesDisabled, setTranscriptLinesDisabled] = useState([])

    const [animationDuration, setAnimationDuration] = useState()
    // Control when user is dragging the progress bar
    const [seeking, setSeeking] = useState(false)

    useImperativeHandle(ref, () => ({
      forward: () => {
        if (playerRef.current) {
          playerRef.current.seekTo(getCurrentTime() + 5)
        }
      },
      backward: () => {
        if (playerRef.current) {
          playerRef.current.seekTo(getCurrentTime() - 5)
        }
      },
      mute: () => setIsMuted(!isMuted),
      disableTranscriptLine: lineControlIndex => {
        const elem = document.getElementsByClassName(
          `transcript-${lineControlIndex}`
        )?.[0]
        const fourLControlElem = document.getElementsByClassName(
          `fourL-control-${lineControlIndex}`
        )?.[0]
        elem && elem.classList.toggle('disable-line')
        fourLControlElem && fourLControlElem.classList.toggle('disable-control')
        toggleLineControlIndex(lineControlIndex)
      },
      handleSeekChange,
      setSeek: seeking => setSeeking(seeking),
      getPlayedValue: () => played * 100,
    }))

    const toggleLineControlIndex = lineControlIndex => {
      setTranscriptLinesDisabled(prevTranscriptLinesDisabled => {
        const index = prevTranscriptLinesDisabled.indexOf(lineControlIndex)
        if (index !== -1) {
          return prevTranscriptLinesDisabled.filter(
            index => index !== lineControlIndex
          )
        } else {
          return [...prevTranscriptLinesDisabled, lineControlIndex]
        }
      })
    }

    const getCurrentTime = () => {
      return playerRef.current ? playerRef.current.getCurrentTime() : 0
    }

    const handleProgress = ({ playedSeconds, played }) => {
      console.log('playedSeconds', playedSeconds)
      const current = subtitles.find(
        sub =>
          playedSeconds >= toSeconds(sub.startTime.split(',')[0]) &&
          playedSeconds <= toSeconds(sub.endTime.split(',')[0])
      )
      console.log('current ===>', current)
      setCurrentSubtitle(current ? transformTranscript(current.transcript) : '')
      setAnimationDuration(
        toSeconds(current ? current.endTime.split(',')?.[0] : '') -
          toSeconds(current ? current.startTime.split(',')?.[0] : '')
      )
      setProgress(played * 100)
      if (!seeking) {
        setPlayed(played * 100)
        setProgress(played * 100) // Set external progress in percentage
      }
    }

    // Sync progress with input range and update video position
    const handleSeekChange = e => {
      const newPlayed = parseFloat(e.target.value)
      setPlayed(newPlayed) // Update local state
      playerRef.current.seekTo(newPlayed) // Seek the video
      setProgress(newPlayed)
    }

    const handlePlayPause = () => {
      setIsPlaying(!isPlaying)
    }

    const FormRow = ({ transcript, index, extraClass = '', textWidth }) => {
      if (!transcript) return null

      return (
        <Box
          sx={{
            display: 'flex',
          }}
          mt={2}
          className={`subtitles transcript-${index} ${
            extraClass !== null && `transcript-${index} ${extraClass}`
          }`}
        >
          {Array.isArray(transcript)
            ? transcript.map((word, index) => (
                <Box
                  textAlign="center"
                  className="subtitle-text"
                  style={textWidth && {'width': textWidth}}
                  pb={1}
                  key={`${word}-${index}`}
                >
                  {word}
                </Box>
              ))
            : transcript}
        </Box>
      )
    }

    const renderMobileSubtitles = subtitles =>
      subtitles &&
      subtitles.map((subtitle, subtitleIndex) => {
        const subtitleContainer = document.getElementsByClassName('courses-subtitles-container')?.[0];
        const subtitleTextWidth = subtitleContainer.clientWidth / 3;
        const isLastSubtitle = subtitleIndex === subtitles.length - 2
        if (Array.isArray(subtitle[0])) {
          return (
            <>
              {subtitle.map((line, index) => {
                if (!transcriptLinesDisabled.includes(index + 1)) {
                  return (
                    <>
                      <FormRow
                        key={`${subtitleIndex}-${index}`}
                        index={index + 1}
                        extraClass={subtitleIndex}
                        transcript={line}
                        textWidth={subtitleTextWidth}
                      />
                    </>
                  )
                }
                return null
              })}
              {!isLastSubtitle && <hr className="separator" />}
            </>
          )
        }
        return (
          !transcriptLinesDisabled.includes(subtitleIndex + 2) && (
            <FormRow extraClass='fourth-line' index={subtitleIndex + 2} transcript={subtitle} />
          )
        )
      })

    const renderSubtitles = subtitles => {
      return (
        subtitles &&
        subtitles.map((line, index) => {
          if (!transcriptLinesDisabled.includes(index + 1)) {
            return <FormRow index={index + 1} transcript={line} />
          }
          return ''
        })
      )
    }

    console.log(subtitles)

    return (
      <>
        <div
          className="video-player__container"
          style={{ display: 'flex', justifyContent: 'center', zIndex: 1 }}
        >
          <ReactPlayer
            ref={playerRef}
            url={videoSrc}
            playing={isPlaying}
            className={`video-player ${
              subtitles.length ? 'fourL-video' : 'normal-video'
            }`}
            controls={false} // Hides YouTube controls
            onProgress={handleProgress}
            muted={isMuted}
          />
          <div className="overlay" onClick={handlePlayPause}></div>
        </div>
        {isMobile && (
          <HStack justifyContent="center" mb={2} mt={2}>
            {subtitles.length && (
              <FourLControls
                isFourLVideo={subtitles.length > 0}
                playerRef={ref}
              />
            )}
          </HStack>
        )}
        {subtitles.length && (
          <div className="courses-subtitles-container">
            {isMobile
              ? renderMobileSubtitles(currentSubtitle)
              : renderSubtitles(currentSubtitle)}
          </div>
        )}
      </>
    )
  }
)

export default VideoPlayer
