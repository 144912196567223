import { useHistory } from 'react-router'
import { useToken } from 'src/hooks/useToken'

import { isMobile } from '../CourseDetail/Practice/Util'
import Banner from './Banner'
import Footer from './Footer'
import InfoSection from './InfoSection'
import LanguagesOffered from './LanguagesOffered'
import MobileBanner from './MobileBanner'
import SectionEight from './SectionEight'
import SectionFive from './SectionFive'
import SectionFour from './SectionFour'
import SectionOne from './SectionOne'
import SectionSeven from './SectionSeven'
import SectionSix from './SectionSix'
import SectionThree from './SectionThree'
import SectionTwo from './SectionTwo'
import Toolbar from './Toolbar'
const { VStack, Box, Text } = require('@chakra-ui/react')

const HomePage = () => {
  const { token } = useToken()
  const history = useHistory()

  if (token) {
    history.push('/language')
    return true
  }
  document.body.classList.remove('loading-indicator') //TODO to REMOVE
  document.body.classList.remove('loading-indicator-mobile') //TODO to REMOVE
  return (
    <VStack align="stretch">
      <Toolbar />
      {!isMobile ? <Banner /> : <MobileBanner />}
      <LanguagesOffered />
      <Box alignItems={'center'} pt="4">
        <Text
          color={'#2469F5'}
          w="100%"
          textAlign={'center'}
          fontSize={['15px', '20px', '28px', '28px']}
          fontWeight={['900']}
          letterSpacing={[2, 1, 2]}
        >
          Why choose langistan?
        </Text>
      </Box>
      <SectionOne></SectionOne>
      <SectionTwo></SectionTwo>
      <SectionThree></SectionThree>
      <SectionFour></SectionFour>
      <SectionFive></SectionFive>
      <SectionSix></SectionSix>
      <SectionSeven></SectionSeven>
      <SectionEight></SectionEight>
      <InfoSection></InfoSection>
      <Footer></Footer>
    </VStack>
  )
}

export default HomePage
