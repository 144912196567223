import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router'
import HomePage from 'src/component/homePage/Homepage'
import LoginSignupTabs from 'src/component/LoginSignupTabs'
import NewHomePage from 'src/component/NewHomePage'
import NotFound from 'src/component/NotFoundPage'
import Privacypolicy from 'src/component/privacy-policy'
import RefundAndReturn from 'src/component/refund-and-returns'
import ResetPassword from 'src/component/ResetPassword'
import TermsConditions from 'src/component/terms-and-conditions'
import { getCourses } from 'src/redux/actions'

export const UnauthenticatedRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCourses())
  }, [])

  return (
    <Switch>
      <Route path="/login" exact={true} component={LoginSignupTabs} />
      <Route
        path="/login/change-password/:resetTokenPassword"
        exact={true}
        component={ResetPassword}
      />
      <Route path="/register" exact={true} component={LoginSignupTabs} />
      <Route path="/oldhome" exact={true} component={HomePage}></Route>
      <Route path="/home" exact={true} component={NewHomePage}></Route>
      <Route path="/privacy-policy" exact={true} component={Privacypolicy}></Route>
      <Route path="/terms-and-conditions" exact={true} component={TermsConditions}></Route>
      <Route path="/refund-and-return" exact={true} component={RefundAndReturn}></Route>
      <Route path="/" exact={true} component={NewHomePage} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
