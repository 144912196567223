import { Box, HStack, Image, Link, List, ListItem } from '@chakra-ui/react'
import facebookIcon from 'src/asset/images/facebookicon.svg'
import instagramIcon from 'src/asset/images/insta.svg'
import twitterIcon from 'src/asset/images/twitter.svg'
import youtubeIcon from 'src/asset/images/youtubeicon.svg'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
import { useHistory } from 'react-router';

import logo from '../../../asset/images/logoBeta.svg'
const Footer = () => {
  const history = useHistory();
  const onFooterLinkClick = (link) => {
    history.push({
      pathname: link,
    })
  }
  return (
    <Box
      display={'flex'}
      flexDirection={['column', 'column', 'row']}
      p={['10px', '10px', '12px 56px']}
      gap={[7, 7, 4]}
      className="footer-container"
    >
      {isMobile && (
        <Box mt={['44px', '']}>
          <Link to="/">
            <img className="" src={logo} alt="logo" width={'136px'} />
          </Link>
        </Box>
      )}

      <Box flex={1} className="footer-item">
      <List spacing={4}>
        <ListItem _hover={{ cursor: 'pointer' }}>About us</ListItem>
        <ListItem _hover={{ cursor: 'pointer' }}>Our Team</ListItem>
        {/* <ListItem _hover={{ cursor: 'pointer' }}>Pricing</ListItem> */}
        <ListItem _hover={{ cursor: 'pointer' }}>Contact</ListItem>
        <ListItem 
          onClick={() => onFooterLinkClick('/refund-and-return')}
          _hover={{ cursor: 'pointer' }}>Return and Refund Policy</ListItem>
        <ListItem
          onClick={() => onFooterLinkClick('/privacy-policy')}
          _hover={{ cursor: 'pointer' }}
        >
          Privacy Policy
        </ListItem>
        <ListItem
          onClick={() => onFooterLinkClick('/terms-and-conditions')}
          _hover={{ cursor: 'pointer' }}
        >
          T&C
        </ListItem>
      </List>
      </Box>
      <Box flex={1} className="footer-item">
        <List spacing={4}>
          <ListItem>Are you having any trouble?</ListItem>
          <ListItem>
            <Link>support@langistan.com</Link>
          </ListItem>
          <ListItem>Address</ListItem>
          <ListItem>Cocoma Digital Pvt Ltd</ListItem>
          <ListItem>
            101, Bombay Bazar, Shourie Complex, JP Rd, Andheri West, Mumbai,
            Maharashtra 400065
          </ListItem>
        </List>
      </Box>
      <Box flex={2} className="follow-us">
        <List fontWeight={'600'} spacing={4}>
          <ListItem>Follow us</ListItem>
          <ListItem>
            <HStack>
              <Image src={facebookIcon} />
              <Image src={youtubeIcon} />
              <Image src={instagramIcon} />
              <Image src={twitterIcon} />
            </HStack>
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default Footer
