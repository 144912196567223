import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import {
  Box,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import Slider from 'react-slick-slider'
import slide1 from 'src/asset/images/slide1.svg'
import slide2 from 'src/asset/images/slide2.svg'
import slide3 from 'src/asset/images/slide3.svg'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'

const OurStudents = () => {
  let settings = {
    dots: false,
    slidesToShow: isMobile ? 1 : 3,
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [youtubeUrl, setYoutubeUrl] = useState()
  return (
    <>
      <VStack className='our-students'>
        <Heading mt="32px" mb={'25px'} fontSize={['20px', '20px', '32px']}>
          In the words of our students
        </Heading>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody className='our-students-content'>
              <iframe
                title="students testimonials"
                width="400"
                height="300"
                src={youtubeUrl}
              ></iframe>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
      <Box className="container">
        <Slider {...settings}>
          <Box>
            <Image
              onClick={() => {
                onOpen()
                setYoutubeUrl(
                  'https://www.youtube.com/embed/IPdZRM_Fj2E?controls=0'
                )
              }}
              src={slide1}
            />
          </Box>
          <Box>
            <Image
              onClick={() => {
                onOpen()
                setYoutubeUrl(
                  'https://www.youtube.com/embed/NSdv67zTc9g?controls=0'
                )
              }}
              src={slide2}
            />
          </Box>
          <Box>
            <Image
              onClick={() => {
                onOpen()
                setYoutubeUrl(
                  'https://www.youtube.com/embed/LdR3eO9EtXU?controls=0'
                )
              }}
              src={slide3}
            />
          </Box>
        </Slider>
      </Box>
    </>
  )
}

export default OurStudents
