import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { Field } from 'formik'

export const CustomField = ({
  UIComponent,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  noBackground,
  ...props
}) => (
  <Flex flexDirection="column" flex={1}>
    <UIComponent
      background={`${
        noBackground ? 'transparent' : '#EFEFEF'
      } 0% 0% no-repeat padding-box;`}
      height={50}
      id={field.name}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <Text color="red">{errors[field.name]}</Text>
    )}
  </Flex>
)

export const TextField = props => (
  <Field component={CustomField} UIComponent={Input} type="text" {...props} />
)

export const EmailField = props => (
  <Field component={CustomField} UIComponent={Input} type="email" {...props} />
)
export const CheckboxField = props => (
  <Field component={CustomField} UIComponent={Checkbox} {...props} />
)
export const PasswordField = ({ name, value, onChange,  minLength, ...inputProps }) => (
  <FormControl>
      <Field
        component={CustomField}
        UIComponent={Input}
        type={value ? 'text' : 'password'}
        name={name}
        id={name}
        minLength={minLength}
        {...inputProps}
      />
      <InputRightElement width="4.5rem" height={50}>
        <Button
          size="sm"
          variant="outline"
          colorScheme={value ? 'black' : 'gray'}
          // color=
          _focus={{ border: 'none' }}
          onClick={() => onChange(!value)}
        >
          {value ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputRightElement>
  </FormControl>
)
