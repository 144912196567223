import { Heading, HStack, Link, Stack, Text, VStack } from '@chakra-ui/react'
import React from "react";
import moment from 'moment'
import { useHistory } from 'react-router'
import useSubscription from 'src/hooks/useSubscription'

export default function UserSubscription() {
  const { subscription, manageSubscrption, cancelSubscrption } =
    useSubscription()
  const history = useHistory()

  const handleCancelSubscription = async subsId => {
    const subscriptionRes = await cancelSubscrption(subsId)
    if (subscriptionRes.code === 200 && !subscriptionRes.error) {
      alert('Subscription cancelled successfully')
      history.push('/language')
      window.location.reload()
    }
  }

  return (
    <Stack gap="1">
      {subscription?.map(sbscrptn => {
        if (sbscrptn.status === 'active')
          return (
            <React.Fragment key={sbscrptn.id} >
              <HStack p="5px" border={'2px solid lightgray'}>
                <VStack w="100%" p="10px" borderRight={'2px solid lightgray'}>
                  <Heading size="sm">Plan Name</Heading>
                  <Text>{sbscrptn?.plan?.product?.name || 'Free Plan'}</Text>
                </VStack>
                <VStack w="100%" p="10px" borderRight={'2px solid lightgray'}>
                  <Heading size="sm">From Date</Heading>
                  <Text>
                    {sbscrptn?.current_period_start &&
                      `${moment(sbscrptn.current_period_start * 1000).format(
                        'DD-MMM-YYYY'
                      )}`}
                  </Text>
                </VStack>
                <VStack w="100%" p="10px" borderRight={'2px solid lightgray'}>
                  <Heading size="sm">To Date</Heading>
                  <Text>
                    {sbscrptn?.current_period_end &&
                      `${moment(sbscrptn.current_period_end * 1000).format(
                        'DD-MMM-YYYY'
                      )}`}
                  </Text>
                </VStack>
                {sbscrptn ? (
                  <>
                    <HStack gap={1} w="100%" p="10px" justifyContent={'center'}>
                      <Link
                        onClick={manageSubscrption}
                        pr="10px"
                        color="#2469F5"
                      >
                        Manage
                      </Link>
                      {/* <Link
                      onClick={() => handleCancelSubscription(sbscrptn?.id)}
                      color="#2469F5"
                    >
                      Cancel
                    </Link> */}
                    </HStack>
                  </>
                ) : (
                  <Link
                    onClick={() => history.push('/pricing')}
                    borderRight={'2px solid lightgray'}
                    pr="10px"
                    color="#2469F5"
                  >
                    Upgrade Plan
                  </Link>
                )}
              </HStack>
              {/* <InputGroup>
              <InputLeftAddon
                p={0}
                justifyContent="center"
                children="Plan Name"
                w={'180px'}
              />
              <Input
                readOnly
                value={sbscrptn?.plan?.product?.name || 'Free Plan'}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon
                p={0}
                justifyContent="center"
                children="From Date"
                w={'180px'}
              />
              <Input
                readOnly
                placeholder="From Date"
                value={
                  sbscrptn?.current_period_start &&
                  `${moment(sbscrptn.current_period_start * 1000).format(
                    'DD-MMM-YYYY'
                  )}`
                }
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon
                p={0}
                justifyContent="center"
                children="To Date"
                w={'180px'}
              />
              <Input
                readOnly
                placeholder="To Date"
                value={
                  sbscrptn?.current_period_end &&
                  `${moment(sbscrptn.current_period_end * 1000).format(
                    'DD-MMM-YYYY'
                  )}`
                }
              />
            </InputGroup> */}
            </React.Fragment>
          )
      })}
      {/* {subscription ? (
        <>
          <Button
            width={'unset'}
            mx="auto"
            variant={'primary'}
            onClick={manageSubscrption}
          >
            Manage Subscription
          </Button>
          <Button
            width={'unset'}
            mx="auto"
            variant={'primary'}
            onClick={handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </>
      ) : (
        <Button
          width={'unset'}
          mx="auto"
          variant={'primary'}
          onClick={() => history.push('/pricing')}
        >
          Upgrade Plan
        </Button>
        // <div>{JSON.stringify(user)}</div>
      )} */}
    </Stack>
  )
}
